import React, { Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react';
import { Moment } from 'moment';
import { useInputOnChangeValue } from '../../../../../../commons/hooks';
import { JoinedFilters } from '../types';
import { useSelectOnChangeValue } from '../../../../../../commons/hooks/useSelectOnChangeValue';

export interface FilterLogicOptions {
  filter: JoinedFilters;
  onChangeFilter: Dispatch<SetStateAction<JoinedFilters>>;
}

export interface FilterSearch {
  search: string;
  searchId: string;
  onSearchChange: (value: React.ChangeEvent<HTMLInputElement>) => void;
  onSearchIdChange: (value: React.ChangeEvent<HTMLInputElement>) => void;
}

export interface FiltersLogicReturn {
  statusId: string;
  userId: string;
  marketplace: string;
  promotion: string;
  endDate: Moment | null;
  startDate: Moment | null;
  onStatusChange: (value: React.ChangeEvent<{ value: unknown }>) => void;
  onUserIdChange: (value: React.ChangeEvent<{ value: unknown }>) => void;
  onMarketplaceChange: (value: React.ChangeEvent<{ value: unknown }>) => void;
  onPromotionsChange: (value: React.ChangeEvent<{ value: unknown }>) => void;
  setStartDate: Dispatch<SetStateAction<Moment | null>>;
  setEndDate: Dispatch<SetStateAction<Moment | null>>;
  gateway: string;
  onGatewayChange: (value: React.ChangeEvent<{ value: unknown }>) => void;
}

export const useFiltersLogic = ({
  filter,
  onChangeFilter,
}: FilterLogicOptions): FiltersLogicReturn & FilterSearch => {
  const [search, setSearch] = useState(filter.search);
  const [searchId, setSearchId] = useState(filter.searchId);
  const [statusId, setStatusId] = useState(filter.statusId);
  const [marketplace, setMarketplace] = useState(filter.marketplace);
  const [userId, setUserId] = useState(filter.userId);
  const [promotion, setPromotion] = useState(filter.promotion);
  const [startDate, setStartDate] = useState(filter.startDate);
  const [endDate, setEndDate] = useState(filter.endDate);
  const [gateway, setGateway] = useState(filter.gateway);
  const onSearchChange = useInputOnChangeValue(setSearch);
  const onSearchIdChange = useInputOnChangeValue(setSearchId);
  const onStatusChange = useSelectOnChangeValue(setStatusId);
  const onUserIdChange = useSelectOnChangeValue(setUserId);
  const onMarketplaceChange = useSelectOnChangeValue(setMarketplace);
  const onPromotionsChange = useSelectOnChangeValue(setPromotion);
  const onGatewayChange = useSelectOnChangeValue(setGateway);

  useEffect(() => {
    const debounce = setTimeout(() => {
      onChangeFilter((oldValue) => ({
        ...oldValue,
        statusId,
        userId,
        promotion,
        marketplace,
        startDate,
        endDate,
        search,
        gateway,
      }));
    }, 600);
    return () => {
      clearTimeout(debounce);
    };
  }, [search, onChangeFilter, statusId, userId, marketplace, startDate, endDate, promotion, gateway]);

  return {
    search,
    statusId,
    searchId,
    userId,
    marketplace,
    startDate,
    endDate,
    promotion,
    onPromotionsChange,
    onStatusChange,
    onSearchChange,
    onUserIdChange,
    onSearchIdChange,
    onMarketplaceChange,
    setStartDate,
    setEndDate,
    gateway,
    onGatewayChange
  };
};
