export enum PAYMENT_STATE {
  PAYMENT_PENDING = 'd1eba8dd-ae2b-4f41-8992-2b450c09ce8f',
  PENDING_SIGNATURE = 'f5934c14-157b-44cb-b362-d9e5ff1cdf0f',
  APPROVED = 'abb0c62c-3555-4a85-9e4a-56c497860105',
  REJECTED = 'e46563c7-686e-41ad-9809-e2973e9f972c',
  DELIVERED = '51785c1d-8890-4e48-b5bd-c81dd495e0ab',
  DOCUMENTATION_CONTROL = 'dbc8e63b-7cc1-4d6d-8114-761814d7d668',
  PENDING_DOCUMENTATION = '58af6c8d-a069-4a33-9ba8-2df2aa325658',
  CONTROLLED_DOCUMENTATION = 'bd9bd35c-6aed-4e9c-b897-97cb2cf55a13',
}
