import React, { useMemo } from 'react';
import {
  DataPersonalCreditDocentAlumnCreditType,
  DataPersonalCreditType,
  ImagePersonalCreditType,
} from '../type/orderDetail';
import { formatDate } from '../../../../utils';
import { Order } from '../../../../app/models/business/Order';

interface UseAllPersonalCreditInfoProps {
  dataOrder: Order;
}

export const useAllPersonalCreditInfo = ({ dataOrder }: UseAllPersonalCreditInfoProps) => {
  const allPersonalCredit: DataPersonalCreditDocentAlumnCreditType[] = useMemo(() => {
    const applicantInfo = dataOrder.payment.metadata?.applicantInfo;
    const guarantorInfo = dataOrder.payment.metadata?.guarantorInfo;
    const allDataPersonalCredit = [];
    const allDetail = [];
    if (applicantInfo) {
      allDataPersonalCredit.push({
        name: 'Solicitante',
        data: applicantInfo,
      });
    }
    if (guarantorInfo) {
      allDataPersonalCredit.push({
        name: 'Garante',
        data: guarantorInfo,
      });
    }
    if (allDataPersonalCredit) {
      allDataPersonalCredit.forEach(({ data, name: title }: DataPersonalCreditType) => {
        const {
          name,
          lastName,
          street,
          streetNumber,
          city: { name: cityName },
          document,
          zipCode,
          apartament,
          floor,
          email,
          dateOfBirty,
          phone: { areaCode, number },
          cbu,
          img_dni_back,
          img_dni_front,
        } = data;
        const dataDetails = [
          {
            name: 'Nombre',
            value: name,
          },
          {
            name: 'Apellido',
            value: lastName,
          },
          {
            name: 'Cuil',
            value: document,
          },
          {
            name: 'CBU',
            value: cbu,
          },
          {
            name: 'Fecha de nacimiento',
            value: formatDate(dateOfBirty, false),
          },
          {
            name: 'Email',
            value: email,
          },
          {
            name: 'Teléfono',
            value: `${areaCode} ${number}`,
          },
          {
            name: 'Dirección',
            value: `${street || ''} ${streetNumber || ''}, ${cityName || ''}, ${
              apartament?.length && apartament !== '0'
                ? `${
                    floor.length && parseInt(floor, 10) > 0 ? `piso ${floor}` : ''
                  } dpto ${apartament},`
                : ''
            }`,
          },
          {
            name: 'Código Postal',
            value: zipCode,
          },
        ];
        const allImagePersonalCredit: ImagePersonalCreditType[] = [
          {
            name: 'Dni frente',
            result: img_dni_front,
          },
          {
            name: 'Dni parte posterior',
            result: img_dni_back,
          },
          {
            name: 'Recibo de sueldo',
            result: data?.img_paycheck,
          },
          {
            name: 'Certificado de servicio',
            result: data?.img_service_certificate,
          },
        ].filter((imagePersonalCredit) => typeof imagePersonalCredit?.result === 'string');

        allDetail.push({
          title,
          data: dataDetails,
          documents: allImagePersonalCredit,
        });
      });
    }

    const docentAlumnInfo = dataOrder.payment.metadata?.docentAlumnInfo;
    if (docentAlumnInfo) {
      const {
        name,
        lastName,
        street,
        streetNumber,
        city: { name: cityName },
        cuil,
        zipCode,
        apartament,
        floor,
        email,
        dateOfBirty,
        phone: { areaCode, number },
        img_dni_front,
        img_dni_back,
        img_certificate,
        img_paycheck,
      } = docentAlumnInfo;
      const isDocent = docentAlumnInfo?.isDocente === 'true';
      const dataDetailsDocentAlumn = [
        {
          name: 'Nombre',
          value: name,
        },
        {
          name: 'Apellido',
          value: lastName,
        },
        {
          name: 'Cuil',
          value: cuil,
        },
        {
          name: 'Fecha de nacimiento',
          value: formatDate(dateOfBirty, false),
        },
        {
          name: 'Email',
          value: email,
        },
        {
          name: 'Teléfono',
          value: `${areaCode} ${number}`,
        },
        {
          name: 'Dirección',
          value: `${street || ''} ${streetNumber || ''}, ${cityName || ''}, ${
            apartament?.length && apartament !== '0'
              ? `${
                  floor.length && parseInt(floor, 10) > 0 ? `piso ${floor}` : ''
                } dpto ${apartament},`
              : ''
          }`,
        },
        {
          name: 'Código Postal',
          value: zipCode,
        },
      ];
      const allDocuments: ImagePersonalCreditType[] = [
        {
          name: 'Dni frente',
          result: img_dni_front,
        },
        {
          name: 'Dni parte posterior',
          result: img_dni_back,
        },
        {
          name: `Certificado de ${isDocent ? 'trabajo' : 'alumno regular'}`,
          result: img_certificate,
        },
      ].filter((imagePersonalCredit) => typeof imagePersonalCredit?.result === 'string');

      if (isDocent && typeof img_paycheck === 'string') {
        allDocuments.push({
          name: `Recibo de sueldo`,
          result: img_paycheck,
        });
      }

      allDetail.push({
        title: isDocent ? 'Docente' : 'Alumno',
        data: dataDetailsDocentAlumn,
        documents: allDocuments,
      });
    }

    const docentInfo = dataOrder.payment.metadata?.docentInfo;
    if (docentInfo) {
      const {
        name,
        lastName,
        street,
        streetNumber,
        city: { name: cityName },
        document,
        zipCode,
        apartament,
        floor,
        email,
        dateOfBirty,
        phone: { areaCode, number },
        img_dni_front,
        img_dni_back,
        img_certificate,
        img_paycheck,
        docentType,
      } = docentInfo;
      const dataDetailsDocent = [
        {
          name: 'Nombre',
          value: name,
        },
        {
          name: 'Apellido',
          value: lastName,
        },
        {
          name: 'Tipo de Docente',
          value: docentType?.name,
        },
        {
          name: 'Cuil',
          value: document,
        },
        {
          name: 'Fecha de nacimiento',
          value: formatDate(dateOfBirty, false),
        },
        {
          name: 'Email',
          value: email,
        },
        {
          name: 'Teléfono',
          value: `${areaCode} ${number}`,
        },
        {
          name: 'Dirección',
          value: `${street || ''} ${streetNumber || ''}, ${cityName || ''}, ${
            apartament?.length && apartament !== '0'
              ? `${
                  floor.length && parseInt(floor, 10) > 0 ? `piso ${floor}` : ''
                } dpto ${apartament},`
              : ''
          }`,
        },
        {
          name: 'Código Postal',
          value: zipCode,
        },
      ];
      const allDocuments: ImagePersonalCreditType[] = [
        {
          name: 'Dni frente',
          result: img_dni_front,
        },
        {
          name: 'Dni parte posterior',
          result: img_dni_back,
        },
        {
          name: `Certificado de trabajo`,
          result: img_certificate,
        },
      ].filter((imagePersonalCredit) => typeof imagePersonalCredit?.result === 'string');

      allDocuments.push({
        name: `Recibo de sueldo`,
        result: img_paycheck,
      });

      allDetail.push({
        title: 'Docente',
        data: dataDetailsDocent,
        documents: allDocuments,
      });
    }

    const alumnInfo = dataOrder.payment.metadata?.alumnInfo;
    if (alumnInfo) {
      const {
        name,
        lastName,
        street,
        streetNumber,
        city: { name: cityName },
        document,
        zipCode,
        apartament,
        floor,
        email,
        dateOfBirty,
        phone: { areaCode, number },
        img_dni_front,
        img_dni_back,
        img_certificate,
      } = alumnInfo;
      const dataDetailsAlumn = [
        {
          name: 'Nombre',
          value: name,
        },
        {
          name: 'Apellido',
          value: lastName,
        },
        {
          name: 'Cuil',
          value: document,
        },
        {
          name: 'Fecha de nacimiento',
          value: formatDate(dateOfBirty, false),
        },
        {
          name: 'Email',
          value: email,
        },
        {
          name: 'Teléfono',
          value: `${areaCode} ${number}`,
        },
        {
          name: 'Dirección',
          value: `${street || ''} ${streetNumber || ''}, ${cityName || ''}, ${
            apartament?.length && apartament !== '0'
              ? `${
                  floor.length && parseInt(floor, 10) > 0 ? `piso ${floor}` : ''
                } dpto ${apartament},`
              : ''
          }`,
        },
        {
          name: 'Código Postal',
          value: zipCode,
        },
      ];
      const allDocuments: ImagePersonalCreditType[] = [
        {
          name: 'Dni frente',
          result: img_dni_front,
        },
        {
          name: 'Dni parte posterior',
          result: img_dni_back,
        },
        {
          name: `Certificado de alumno regular`,
          result: img_certificate,
        },
      ].filter((imagePersonalCredit) => typeof imagePersonalCredit?.result === 'string');

      allDetail.push({
        title: 'Alumno',
        data: dataDetailsAlumn,
        documents: allDocuments,
      });
    }
    return allDetail;
  }, [dataOrder]);

  return { allPersonalCredit };
};
