export enum GATEWAY_STATUS {
  FONDOS_INSUFICIENTES = 51,
  DENEGADA = 5,
  TRANS_NO_PERMITIDA = 57
}

export const GatewayStatuses = [
  { name: "Fondos Insuficientes", value: GATEWAY_STATUS.FONDOS_INSUFICIENTES },
  { name: "Denegada", value: GATEWAY_STATUS.DENEGADA },
  { name: "Transaccion no permitida", value: GATEWAY_STATUS.TRANS_NO_PERMITIDA}
];
