import React, { useCallback } from 'react';
import { DatabaseConnector } from '@phinxlab/libby-rest-web';
import {
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Grid,
  ListItem,
  ListItemAvatar,
  ListItemText,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { LibbyObject } from 'src/commons/types';
import { grey, primary } from 'src/theme/colors';
import ExitToAppSharpIcon from '@material-ui/icons/ExitToAppSharp';
import { useRolesContext } from 'src/context';
import pallet from '../../../theme/palette';
import logo from '../../../assets/images/novatech-logo.svg';
import { UserCard } from '../UserCard';
import { UserAvatar } from '../UserAvatar';

const useStyles = makeStyles(() => ({
  container: {
    backgroundColor: pallet.background.defaultDark,
    height: 100,
    justifyContent: 'center',
    borderBottom: '1px solid gray',
  },
  menuContainer: {
    backgroundColor: pallet.background.defaultDark,
    height: 55,
  },
  action: {
    fontWeight: 'bold',
    color: 'white',
    marginTop: -9,
  },
  footerMenu: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    paddingTop: 15,
  },
  footerText: {
    color: primary.white,
    fontSize: 14,
    paddingLeft: 15,
    '&:hover': {
      color: primary.lightBlue,
    },
  },
  iconStyle: {
    fontSize: 20,
    color: grey.heading,
  },
}));

const menuButtons = [{ label: 'INICIO' }];

interface HeaderProps {
  libby: LibbyObject;
}

const HeaderRAW = ({ libby }: HeaderProps) => {
  const classes = useStyles();
  const { userInfo, selectedRole, deselectRole } = useRolesContext();
  const onLogout = useCallback(async () => {
    localStorage.removeItem('X-JustRPC-Token');
    deselectRole();
    window.location.reload();
  }, [deselectRole]);
  return (
    <>
      <AppBar position="static" className={classes.container}>
        <Grid container direction="row" justify="space-between">
          <Toolbar style={{ marginLeft: '15' }}>
            <img src={logo} alt="logo" />
          </Toolbar>
          {selectedRole.roleUserId && <UserCard logout={onLogout} />}
        </Grid>
      </AppBar>
    </>
  );
};

export const Header = DatabaseConnector(HeaderRAW)();
