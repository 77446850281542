import React, { useContext, useState } from 'react';
import {
  Box,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  IconButton,
  makeStyles,
  Radio,
  RadioGroup,
  Typography,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import { OperationOrderGateway } from 'src/app/models/business/OpertationOrderGateway';
import {
  FormRadioNoForm,
  FormRadioNoFormOption,
} from 'src/lib/templates/components/FormRadioNoForm';
import { MainButton, MainInput, useFetch } from '../../../../../commons';
import { grey, primary } from '../../../../../theme/colors';
import { OrderDetailContext } from '../../context';
import { PaymentIdInputValue } from '../../hooks/usePaymentIDInputs';
import { loading } from '../../../../../assets/images';

const styles = makeStyles(() => ({
  container: {
    paddingTop: 15,
    boxSizing: 'border-box',
  },
  iconColor: { color: primary.paleBlueStrong },
  iconColorDisabled: { color: grey.textSecondary },
  inputContainer: {
    display: 'flex',
    marginBottom: 10,
  },
  title: {
    marginBottom: 10,
    fontSize: 16,
    lineHeight: '17px',
    fontWeight: 400,
  },
}));

interface PaymentIDInputProps {
  gatewayId: string | null;
  handleChange: (event: React.ChangeEvent<HTMLInputElement>, value: string) => void;
  operationOrderGateway: FormRadioNoFormOption[];
}

export const PaymentIDInput = ({
  gatewayId,
  handleChange,
  operationOrderGateway,
}: PaymentIDInputProps) => {
  const classes = styles();
  const {
    paymentIdInputValues,
    addPaymentIdInput,
    removePaymentIdInput,
    onChangePaymentIdInput,
    loadingPaymentIdInputs,
    disabled,
  } = useContext(OrderDetailContext);

  if (loadingPaymentIdInputs)
    return (
      <div className={classes.container}>
        <Grid container justify="center" alignItems="center">
          <img src={loading} width={50} alt="loading" />
        </Grid>
      </div>
    );
  return (
    <div className={classes.container}>
      {(!!paymentIdInputValues.length || gatewayId !== null) && (
        <Box>
          <Typography variant="h5" className={classes.title}>
            Medio de pago:
          </Typography>
          <FormRadioNoForm
            value={gatewayId}
            onChange={handleChange}
            options={operationOrderGateway}
          />
        </Box>
      )}
      {paymentIdInputValues.length ? (
        <>
          {paymentIdInputValues.map((input: PaymentIdInputValue, index: number) => (
            <div key={`payment-id-input-${index}`} className={classes.inputContainer}>
              <MainInput
                value={paymentIdInputValues[index].value}
                handleChange={(e) => onChangePaymentIdInput(index, e.target.value)}
                placeholder="Payment id"
              />
              <IconButton onClick={addPaymentIdInput} disabled={disabled}>
                <AddIcon
                  className={!disabled && index < 3 ? classes.iconColor : classes.iconColorDisabled}
                />
              </IconButton>
              <IconButton onClick={() => removePaymentIdInput(index)} disabled={disabled}>
                <RemoveIcon className={!disabled ? classes.iconColor : classes.iconColorDisabled} />
              </IconButton>
            </div>
          ))}
        </>
      ) : (
        <MainButton title="Agregar ID de pago" handleOnClick={addPaymentIdInput} />
      )}
    </div>
  );
};
