import { MenuItem } from 'src/commons';
import { routes } from './routes';

export const promotionOperationMenu: MenuItem = {
  key: 'promotionoperation',
  basePath: '/promotionoperation',
  enabled: true,
  icon: 'List',
  text: 'Promotion Operation',
  children: routes,
};
