import { routes } from './routes';
import { MenuItem } from '../../../../commons/ui/menu';

export const homeMenu: MenuItem = {
  key: 'home',
  basePath: '/home',
  enabled: true,
  icon: 'List',
  text: 'Home',
  children: routes,
};
