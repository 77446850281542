import React, { useCallback } from 'react';
import { CONFIGURATION_IMAGE_CARD, CardButton } from 'src/commons';
import { Container, Grid } from '@material-ui/core';
import { PermissionBlocker } from 'src/lib/permission';
import { useHistory } from 'react-router';
import { useRouteScreenTitle } from '../../../commons/hooks';
import { BLOCKER_ID } from '../../../platform/permission/const';
import { MenuCardItem } from '../../../commons/components/MenuCardList';

export const configurationItems: MenuCardItem[] = [
  {
    id: BLOCKER_ID.EJEMPLO_BLOCKER_SEGUNDO,
    title: 'Ordenes',
    img: CONFIGURATION_IMAGE_CARD.APROBACION_ROLES,
    path: '/private/promotionoperation',
  },
];

export const Home = () => {
  useRouteScreenTitle('Configuración Administración');
  const history = useHistory();
  const onCardClick = useCallback(
    (item: MenuCardItem) => {
      history.replace(item.path);
    },
    [history],
  );
  return (
    <Container>
      <Grid container spacing={2} style={{ paddingTop: 30 }}>
        {configurationItems.map((item) => (
          <PermissionBlocker key={item.id} id={item.id}>
            <Grid item sm={3}>
              <CardButton<MenuCardItem>
                title={item.title}
                image={item.img}
                data={item}
                onClick={onCardClick}
              />
            </Grid>
          </PermissionBlocker>
        ))}
      </Grid>
    </Container>
  );
};
