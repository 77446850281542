import React from 'react';
import { Box, Grid, makeStyles, Typography, List, ListItem, ListItemText } from '@material-ui/core';
import { Link } from 'react-router-dom';
import logo from '../../../assets/images/logo-footer-nova.png';
import { SPACING } from '../../const';
import { Padding } from '../Padding';

const useStyles = makeStyles((theme) => ({
  contactanos: {
    color: '#666',
    cursor: 'pointer',
    fontWeight: 500,
    marginLeft: 10,
    fontSize: 24,
    margin: '20px 0 50px',
    verticalAlign: 'top',
  },
  title: {
    // color: pallet.common.white,
    color: '#222',
    fontSize: 14,
    fontWeight: 500,
    textTransform: 'uppercase',
    margin: '0 0 30px',
    padding: '25px 0 0 0',
  },
  body: {
    // color: pallet.common.white,
    fontSize: 18,
    lineHeight: '36px',
    fontWeight: 'normal',
  },
  innerContainer: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  listText: {
    // color: pallet.common.white,
    fontSize: 14,
    fontWeight: 400,
    textDecoration: 'none',
    color: '#666',
  },
}));

const masInformacion = [
  { label: 'Inicio', url: 'https://novatechstore.com.ar' },
  { label: 'Cómo Comprar', url: 'https://novatechstore.com.ar/como-comprar' },
  { label: 'Política de Devolución', url: 'https://novatechstore.com.ar/politicas' },
  { label: 'Quiénes Somos', url: 'https://novatechstore.com.ar/quienes-somos' },
  { label: 'Preguntas Frecuentes', url: 'https://novatechstore.com.ar/preguntas-frecuentes' },
];

const seguinos = [
  { label: 'Facebook', url: 'https://www.facebook.com/NovatechStoreArg' },
  { label: 'Instagram', url: 'http://instagram.com/novatechstore' },
];

export const Footer = () => {
  return null;
  // const classes = useStyles();
  // return (
  //   <Box bgcolor="white" minHeight={400}>
  //     <Padding>
  //       <Grid container spacing={SPACING.COMMON} className={classes.innerContainer}>
  //         <Grid item sm={12} md={12} lg={4}>
  //           <Grid container style={{ height: '100%' }}>
  //             <Grid item>
  //               <img src={logo} alt="logo" />
  //             </Grid>
  //             <Grid item>
  //               <Typography variant="h2" className={classes.contactanos}>
  //                 Contactanos
  //               </Typography>
  //               <Typography variant="body1" className={classes.body}>
  //                 Direccion: Uspallata 2776, Parque Patricios.
  //               </Typography>
  //               <Typography variant="body1" className={classes.body}>
  //                 Email: contacto@novatech.com.ar
  //               </Typography>
  //               <Typography variant="body1" className={classes.body}>
  //                 Telefono: 011 7700-3333
  //               </Typography>
  //             </Grid>
  //           </Grid>
  //         </Grid>
  //
  //         <Grid item sm={12} md={4} lg={3}>
  //           <Typography variant="h2" className={classes.title}>
  //             Mas Información
  //           </Typography>
  //           <List disablePadding>
  //             {masInformacion.map((item) => (
  //               <ListItem
  //                 disableGutters
  //                 component="a"
  //                 key={item.label}
  //                 button
  //                 href={item.url}
  //                 target="_blank"
  //               >
  //                 <ListItemText
  //                   primary={item.label}
  //                   primaryTypographyProps={{ className: classes.listText }}
  //                 />
  //               </ListItem>
  //             ))}
  //           </List>
  //         </Grid>
  //
  //         <Grid item sm={12} md={4} lg={2}>
  //           <Typography variant="h2" className={classes.title}>
  //             Seguinos
  //           </Typography>
  //           <List disablePadding>
  //             {seguinos.map((item) => (
  //               <ListItem
  //                 disableGutters
  //                 component="a"
  //                 key={item.label}
  //                 button
  //                 href={item.url}
  //                 target="_blank"
  //               >
  //                 <ListItemText
  //                   primary={item.label}
  //                   primaryTypographyProps={{ className: classes.listText }}
  //                 />
  //               </ListItem>
  //             ))}
  //           </List>
  //         </Grid>
  //         <Grid item sm={12} md={4} lg={2}>
  //           <Typography variant="h2" className={classes.title}>
  //             Reciba Ofertas & Descuentos Via E-MAIL
  //           </Typography>
  //           <Typography variant="h2" className={classes.title}>
  //             ¡Suscribite y enterate de todas nuestras ofertas!
  //           </Typography>
  //         </Grid>
  //       </Grid>
  //     </Padding>
  //   </Box>
  // );
};
