import { MenuItem } from '../../../../commons/ui/menu';
import { HomePublic } from '../../../../screens/Public/HomePublic';

export const routes: MenuItem[] = [
  {
    key: 'publicHomeMain',
    basePath: '/',
    target: {
      component: HomePublic,
      path: '/',
    },
    enabled: true,
    icon: 'List',
    text: 'Home',
    children: [],
    default: true,
  },
];
