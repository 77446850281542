import React, { FunctionComponent } from 'react';
import { makeStyles } from '@material-ui/core';
import { MenuCollection } from '../../ui/menu';
import { Header } from '../Header';
import { Footer } from '../Footer';

const useStyles = makeStyles(() => ({
  container: {
    overflowX: 'hidden',
  },
  mainContainer: {
    minHeight: 800,
  },
}));

interface MainLayoutProps {
  children: React.ReactNode;
  menu: MenuCollection;
}

export const MainLayout: FunctionComponent<MainLayoutProps> = ({ children }) => {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <Header />
      <main className={classes.mainContainer}>{children}</main>
      <Footer />
    </div>
  );
};
