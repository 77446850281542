import React, { useMemo } from 'react';
import { ShipmentAddress } from 'src/app/models/business/ShipmentAddress';
import { InfoTable } from 'src/commons';

interface DeliveryInfoProps {
  rows: Array<ShipmentAddress>;
}

export const DeliveryInfo = ({ rows }: DeliveryInfoProps) => {
  const columns = useMemo(
    () => [
      {
        id: 'province',
        label: 'Provincia',
        width: '30%',
        hideSortIcon: true,
        render: (value: string, row: ShipmentAddress) => row.state,
      },
      {
        id: 'city',
        label: 'Ciudad',
        width: '20%',
        hideSortIcon: true,
        render: (value: number, row: ShipmentAddress) => row.city,
      },
      {
        id: 'postalCode',
        label: 'Cod. Postal',
        width: '10%',
        hideSortIcon: true,
        render: (value: number, row: ShipmentAddress) => row.zip,
      },
      {
        id: 'address',
        label: 'Dirección',
        width: '20%',
        hideSortIcon: true,
        render: (value: number, row: ShipmentAddress) =>
          `${row.street} ${row.number} ${Number(row.floor) || ''} ${row.department || ''}`,
      },
      {
        id: 'coments',
        label: 'Comentario',
        width: '20%',
        hideSortIcon: true,
        render: (value: number, row: ShipmentAddress) => row.comments,
      },
    ],
    [],
  );
  return <InfoTable columns={columns} rows={rows} />;
};
