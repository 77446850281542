import React, { Dispatch, SetStateAction } from 'react';
import { Grid } from '@material-ui/core';
import {
  GatewayStatuses,
  SimpleSelect,
  useFetch,
  isCatamarcaUser,
} from 'src/commons';
import { grey } from 'src/theme/colors';
import { DateClose } from 'src/app/components/inputs/Input/DateClose';
import { useFiltersLogic } from './hooks';
import { MainInput } from '../../../../../commons/components/MainInput';
import { JoinedFilters } from './types';
import { useRolesContext } from '../../../../../context';

export type PromotionOperationHeaderProps = {
  filter: JoinedFilters;
  onChangeFilter: Dispatch<SetStateAction<JoinedFilters>>;
};

const titleStyle = {
  color: grey.medium,
  fontWeight: 'normal',
  verticalAlign: 'center',
};

export const PromotionOperationHeader = ({
  filter,
  onChangeFilter,
}: PromotionOperationHeaderProps) => {
  const { selectedRole } = useRolesContext();
  const isRolCatamarca = isCatamarcaUser(selectedRole.roleUserId.toString());

  const { data: marketplaces, loading: loadingMarket } = useFetch('/marketplace');
  const { data: status, loading: loadingStatus } = useFetch('/operation/status');
  const { data: accounts, loading: loadingAccounts } = useFetch('/account');
  const { data: promotions, loading: loadingPromotions } = useFetch('/promotion');

  const props = {
    filter,
    onChangeFilter,
  };
  const {
    statusId,
    onStatusChange,
    marketplace,
    onMarketplaceChange,
    userId,
    onUserIdChange,
    search,
    onSearchChange,
    startDate,
    setStartDate,
    endDate,
    setEndDate,
    promotion,
    onPromotionsChange,
    gateway,
    onGatewayChange,
  } = useFiltersLogic(props);

  return (
    <Grid container justify="space-between" spacing={3}>
      <Grid item xs={12} sm={8} container spacing={3}>
        <Grid item xs={12} sm={2} style={{ marginTop: 20 }}>
          <SimpleSelect
            value={statusId}
            placeholder="Estado"
            onChange={onStatusChange}
            content={status || []}
            valueKey="operation_order_state_id"
            labelKey="name"
            idValue="operation_order_state_id"
            loading={loadingStatus}
          />
        </Grid>
        {!isRolCatamarca && (
          <Grid item xs={12} sm={2} style={{ marginTop: 20 }}>
            <SimpleSelect
              value={marketplace}
              placeholder="Marketplace"
              onChange={onMarketplaceChange}
              content={marketplaces || []}
              valueKey="name"
              labelKey="name"
              idKey="marketplace_id"
              loading={loadingMarket}
            />
          </Grid>
        )}
        <Grid item xs={12} sm={2} style={{ marginTop: 20 }}>
          <SimpleSelect
            value={userId}
            placeholder="Usuario"
            onChange={onUserIdChange}
            content={accounts || []}
            valueKey="account_id"
            labelKey="username"
            idValue="account_id"
            loading={loadingAccounts}
          />
        </Grid>
        <Grid item xs={12} sm={2} style={{ marginTop: 20 }}>
          <SimpleSelect
            value={promotion}
            placeholder="Promoción"
            onChange={onPromotionsChange}
            content={promotions || []}
            valueKey="name"
            labelKey="name"
            idValue="promotion_id"
            loading={loadingPromotions}
          />
        </Grid>
        {!isRolCatamarca && (
          <Grid item xs={12} sm={2} style={{ marginTop: 20 }}>
            <SimpleSelect
              value={gateway}
              placeholder="Gateway"
              onChange={onGatewayChange}
              content={GatewayStatuses}
              valueKey="value"
              labelKey="name"
              idValue="value"
            />
          </Grid>)}
      </Grid>
      <Grid item xs={12} sm={8} container spacing={3}>
        <Grid item xs={12} sm={3} style={{ marginTop: 20 }}>
          <DateClose name="Start Date" value={startDate} onChange={setStartDate} />
        </Grid>
        <Grid item xs={12} sm={3} style={{ marginTop: 20 }}>
          <DateClose name="End Date" value={endDate} minValue={startDate} onChange={setEndDate} />
        </Grid>
      </Grid>
      <Grid item xs={12} sm={3} container spacing={3}>
        <Grid item xs={12}>
          <MainInput
            label="Buscar"
            type="search"
            handleChange={onSearchChange}
            placeholder="Buscar"
            name="search"
            value={search}
            fullWidth
            customStyle={{ marginTop: 5 }}
            customStyleLabel={titleStyle}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};
