import { useMemo } from 'react';
import { PAYMENT_REFERENCE, PAYMENT_STATE } from '../../../../commons';
import { MARKETPLACE } from '../../../../commons/const/marketplace';
import { Order } from '../../../../app/models/business/Order';
import { OperationStatus } from '../../../../app/models/business/OperationStatus';
import { paymentMethodPaymentPending, paymentMethodDocumentationControl, paymentMethodPendingDocumentation, paymentMethodControlledDocumentation, paymentMethodPendingSignature } from '../const/paymentMethod';

interface UseStatusPaymentProps {
  order: Order;
  statusPayments: OperationStatus[];
}

const STATUS_PAYMENTS_ENABLED_IN_DETECNOLOGIA = [
  PAYMENT_STATE.PAYMENT_PENDING,
  PAYMENT_STATE.PENDING_SIGNATURE,
];

export const useStatusPayment = ({ order, statusPayments }: UseStatusPaymentProps) => {
  const statusPaymentsEnabledWithMarketplace = useMemo(() => {
    const {
      marketplace: { marketplace_id },
      payment: {
        payment_state: { payment_state_id },
        reference,
      },
    } = order;

    const statusPaymentsDetecnologia =
      payment_state_id === PAYMENT_STATE.PAYMENT_PENDING
        ? paymentMethodPaymentPending
        : payment_state_id === PAYMENT_STATE.DOCUMENTATION_CONTROL
        ? paymentMethodDocumentationControl
        : payment_state_id === PAYMENT_STATE.PENDING_DOCUMENTATION 
        ? paymentMethodPendingDocumentation
        : payment_state_id === PAYMENT_STATE.CONTROLLED_DOCUMENTATION
        ? paymentMethodControlledDocumentation 
        : payment_state_id === PAYMENT_STATE.PENDING_SIGNATURE 
        ? paymentMethodPendingSignature 
        : [];

    const statusPaymentsEnabledPaymentPending = statusPaymentsDetecnologia.length
      ? statusPayments.filter((statePayment) =>
          statusPaymentsDetecnologia.includes(statePayment.operation_order_state_id),
        )
      : [];

    const statusPaymentsNotDetecnologia = statusPayments.filter(
      ({ operation_order_state_id }) =>
        !STATUS_PAYMENTS_ENABLED_IN_DETECNOLOGIA.includes(
          operation_order_state_id as PAYMENT_STATE,
        ),
    );

    const allStatusPayments =
      statusPaymentsEnabledPaymentPending.length &&
      marketplace_id === MARKETPLACE.DETECNOLOGIA &&
      reference === PAYMENT_REFERENCE.CREDIT
        ? statusPaymentsEnabledPaymentPending
        : statusPaymentsNotDetecnologia;

    return allStatusPayments.filter(
      ({ operation_order_state_id }) => operation_order_state_id !== PAYMENT_STATE.DELIVERED,
    );
  }, [order, statusPayments]);

  return statusPaymentsEnabledWithMarketplace;
};
