import React, { useState } from 'react';
import { Drawer, Grid, List, ListItem, IconButton, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import NotificationsNoneOutlinedIcon from '@material-ui/icons/NotificationsNoneOutlined';
import { NavLink, useLocation } from 'react-router-dom';
import { SIDEMENU_ID } from 'src/platform/permission/const';
import { DatabaseConnector } from '@phinxlab/libby-rest-web';
import { LogoBA } from '../../../assets/images/svgs/LogoBA';
import { grey, primary } from '../../../theme/colors/index';
import { LibbyObject } from '../../types';

const drawerWidthOpen = 220;
const drawerWithClose = 70;

const navBarStyles = makeStyles(() => ({
  root: {
    display: 'flex',
  },
  drawerOpenContainer: {
    transition: '0.8s',
    width: drawerWidthOpen,
    marginLeft: 15,
  },
  drawerCloseContainer: {
    transition: '0.8s',
    width: drawerWithClose,
    marginLeft: 15,
  },
  drawerPaperOpen: {
    transition: '0.8s',
    marginTop: 10,
    width: drawerWidthOpen,
  },
  drawerPaperClose: {
    transition: '0.8s',
    marginTop: 10,
    width: drawerWithClose,
  },
  logoStyle: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  iconContainer: {
    height: 37,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  selectedIcon: {
    textDecoration: 'none',
    height: 37,
    width: drawerWidthOpen,
    color: primary.white,
    backgroundColor: primary.lightBlue,
    display: 'flex',
    paddingLeft: 25,
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  defaultIcon: {
    textDecoration: 'none',
    height: 37,
    width: drawerWidthOpen,
    paddingLeft: 25,
    color: grey.medium,
    backgroundColor: primary.white,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  arrowContainer: {
    flexDirection: 'row',
    alignSelf: 'center',
  },
  arrowIcon: {
    paddingTop: 10,
    fontSize: 30,
    height: 30,
    color: grey.medium,
  },
  titleStyle: {
    transition: '0.5s',
    fontSize: 13,
    paddingLeft: 15,
    color: grey.medium,
    textDecoration: 'none',
  },
  selectedTitle: {
    transition: '0.5s',
    fontSize: 13,
    paddingLeft: 15,
    color: primary.white,
    textDecoration: 'none',
  },
  iconButtonStyle: {
    backgroundColor: primary.white,
    '&:hover': {
      backgroundColor: primary.white,
    },
  },
}));

const sideMenuIcons = [
  {
    id: SIDEMENU_ID.EJEMPLO_SIDEBAR,
    title: 'Ejemplo',
    icon: <NotificationsNoneOutlinedIcon />,
    path: '/',
  },
];

const SideMenuRaw = ({ libby }: { libby: LibbyObject }) => {
  const [isOpen, setIsOpen] = useState(false);
  const classes = navBarStyles();
  const location = useLocation();


  return (
    <div className={classes.root}>
      <nav className={isOpen ? classes.drawerOpenContainer : classes.drawerCloseContainer}>
        <Drawer
          classes={{
            paper: isOpen ? classes.drawerPaperOpen : classes.drawerPaperClose,
          }}
          variant="persistent"
          open
        >
          <Grid className={classes.logoStyle}>
            <LogoBA />
          </Grid>
          <IconButton onClick={() => setIsOpen(!isOpen)} className={classes.iconButtonStyle}>
            {isOpen ? (
              <KeyboardArrowRightIcon style={{ transform: 'rotate(180deg)', transition: '0.8s' }} />
            ) : (
              <KeyboardArrowRightIcon style={{ transition: '0.8s' }} />
            )}
          </IconButton>
          <List>
            {sideMenuIcons.map((item, index) => (
              <ListItem button key={index} disableGutters className={classes.iconContainer}>
                <NavLink
                  to={item.path}
                  className={
                    item.path === location.pathname ? classes.selectedIcon : classes.defaultIcon
                  }
                >
                  {item.icon}
                  {isOpen && (
                    <Typography
                      className={
                        item.path === location.pathname ? classes.selectedTitle : classes.titleStyle
                      }
                    >
                      {item.title}
                    </Typography>
                  )}
                </NavLink>
              </ListItem>
            ))}
          </List>
        </Drawer>
      </nav>
    </div>
  );
};

export const SideMenu = DatabaseConnector(SideMenuRaw)();
