import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Avatar, ListItem, ListItemAvatar, ListItemText, Typography } from '@material-ui/core';
import { primary } from '../../../theme/colors';

interface AvatarProps {
  name?: string;
  img?: string;
  rol: string;
  avatarWidth: number;
  avatarHeight: number;
}

export const UserAvatar = ({ name, rol, avatarWidth, avatarHeight, img }: AvatarProps) => {
  const AvatarStyles = makeStyles(() => ({
    avatarText: {
      padding: 5,
      width: avatarWidth,
      height: avatarHeight,
      backgroundColor: primary.lightBlue,
      fontSize: avatarWidth >= 35 ? 30 : 15,
      fontFamily: 'Open Sans',
      color: primary.white,
      borderRadius: 8,
    },
    avatarImage: {
      backgroundColor: 'white',
      padding: 5,
      width: avatarWidth,
      height: avatarHeight,
      backgroundImage: img,
    },
  }));
  const classes = AvatarStyles();
  const getInitials = () => {
    if (name) {
      const names = name.split(' ');
      let initials = names[0].substring(0, 1).toUpperCase();
      if (names.length > 1) {
        initials += names[names.length - 1].substring(0, 1).toUpperCase();
      }
      return initials;
    }
  };
  return (
    <ListItem button>
      <ListItemAvatar>
        <Avatar variant="square" className={classes.avatarText}>
          {getInitials()}
        </Avatar>
      </ListItemAvatar>
      <ListItemText primary={<Typography variant="h4">{name}</Typography>} secondary={rol} />
    </ListItem>
  );
};
