import { useCallback, useState } from 'react';

const initialState = [] as PaymentIdInputValue[];

export interface PaymentIdInputValue {
  value: string
}

export const usePaymentIDInputs = () => {
  const [inputValues, setInputValues] = useState<PaymentIdInputValue[]>(initialState);
  const [loading, setLoading] = useState(true);

  const onChangeInput = useCallback((index: number, value: string) => {
    const newValues = inputValues.map((input, i) => {
      if (i === index) {
        return { ...inputValues[i], value }
      }
      return input;
    });
    setInputValues(newValues);
  }, [inputValues]);

  const addInput = useCallback(() => {
    if (inputValues.length === 4) return;
    setInputValues(prevState => [...prevState, { value: "" }]);
  }, [inputValues]);

  const removeInput = useCallback((index: number) => {
    setInputValues(inputValues.filter((v, i) => i !== index))
  }, [inputValues]);

  const setValues = useCallback((values: string[]) => {
    if (!Array.isArray(values)) return;
    setInputValues(values.map(value => ({ value })));
  }, []);

  return { inputValues, onChangeInput, removeInput, addInput, setValues, loading, setLoading };
};
