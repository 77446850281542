import { MenuItem } from '../../../../commons/ui/menu';
import { Home } from '../../../../screens/Private/Home';

export const routes: MenuItem[] = [
  {
    key: 'homeMain',
    basePath: '/',
    target: {
      component: Home,
      path: '/',
    },
    enabled: true,
    icon: 'List',
    text: 'Home',
    children: [],
    default: true,
  },
];
