import React, { useState, useEffect } from 'react';
import { makeStyles, Grid, Box } from '@material-ui/core';
import { useRouteScreenTitle, HeaderTable, InfoTable, useOrderByState } from 'src/commons';
import { useOrderColumns } from './hooks/useOrderColumns';
import { JoinedFilters } from './components/PromotionOperationHeader/types';
import { PromotionOperationHeader } from './components';
import { useOrdersData } from './hooks/useData';

const useStyles = makeStyles({
  typography: {
    fontSize: 12,
  },
});

const initialStartDate = null;
const initialEndDate = null;

export const Orders = () => {
  const {
    data,
    updateOrder,
    loading,
    loadMore,
    loadingMore,
    onFilter,
    reLoad,
    onOrderByChange,
  } = useOrdersData();
  const classes = useStyles();
  useRouteScreenTitle('Ordenes');
  const [filter, setFilter] = useState<JoinedFilters>({
    search: '',
    marketplace: '',
    searchId: '',
    statusId: '',
    userId: '',
    promotion: '',
    startDate: initialStartDate,
    endDate: initialEndDate,
    gateway: "",
  });

  const { direction, orderBy, onOrderByDirectionChange } = useOrderByState();

  const column = useOrderColumns({ updateOrder, reLoad });

  useEffect(() => {
    onFilter(filter);
  }, [filter, onFilter]);

  useEffect(() => {
    onOrderByChange(orderBy, direction);
  }, [orderBy, direction, onOrderByChange]);

  return (
    <Grid container>
      <Box p={5} width="100%">
        <HeaderTable title="Filtros">
          <PromotionOperationHeader filter={filter} onChangeFilter={setFilter} />
        </HeaderTable>
        <HeaderTable title="Ordenes" />
        <InfoTable
          working={loading || loadingMore}
          rows={data}
          columns={column}
          rowIdKey="order_id"
          customClassnameTypography={classes.typography}
          onBottomScroll={loadMore}
          showCount
          direction={direction}
          orderBy={orderBy}
          onSortChange={onOrderByDirectionChange}
        />
      </Box>
    </Grid>
  );
};
