import React, { FunctionComponent, useCallback, useEffect, useState } from 'react';
import axios from 'axios';
import { GoogleAnalytic } from 'src/app/services/GoogleAnalytic';
import { PhinxApp } from './lib/profiles/PhinxApp';
import { useProfileContext } from './lib/profiles/ProfileContext';
import { Loading } from './commons/components/Loading';
import { useRolesContext } from './context';
import { API_URL } from './config';
import { Axios } from './lib/axios';

GoogleAnalytic.initialize();

export const App: FunctionComponent = () => {
  const [isFirstLoading, setIsFirstLoading] = useState(true);
  const [initialProfileLoaded, setInitialProfileLoaded] = useState(false);
  const [isLogged, setIsLogged] = useState<boolean | undefined>(undefined);
  const { setProfile } = useProfileContext();
  const { setSelectedRole, setUserInfo } = useRolesContext();

  const checkSession = useCallback(async () => {
    try {
      const { data } = await axios.get(`${API_URL}/session`, {
        headers: { 'X-JustRPC-Token': localStorage.getItem('X-JustRPC-Token') },
      });
      if (data.isGuest) throw new Error('Logout');
      const rol = data.user.groups[0];
      setUserInfo(data.user);
      setSelectedRole({
        roleUserId: rol.id,
        roleName: rol.name,
      });
      setIsLogged(true);
      Axios.defaults.headers.common['X-JustRPC-Token'] = localStorage.getItem('X-JustRPC-Token');
    } catch (e) {
      console.log('e', e);
    } finally {
      setIsFirstLoading(false);
    }
  }, [setUserInfo, setSelectedRole]);

  useEffect(() => {
    checkSession();
  }, [checkSession]);

  useEffect(() => {
    if (!isFirstLoading) {
      if (!isLogged) {
        setProfile('public');
      } else {
        setProfile('private');
      }
      setInitialProfileLoaded(true);
    }
  }, [isFirstLoading, isLogged, setProfile]);

  return !initialProfileLoaded ? <Loading /> : <PhinxApp />;
};
