import React, { useState } from 'react';
import { Menu, Typography, Grid, Divider } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/styles';
import { UserAvatar } from 'src/commons/components/UserAvatar';
import { AnyObject } from 'src/commons/types';
import { useRolesContext } from 'src/context/RolesContext/RolesContext';
import LockOpenOutlinedIcon from '@material-ui/icons/LockOpenOutlined';
import { primary, grey } from 'src/theme/colors';
import KeyboardArrowDownOutlinedIcon from '@material-ui/icons/KeyboardArrowDownOutlined';

const useStyles = makeStyles({
  menuText: {
    fontFamily: 'Open Sans',
    '&:hover': {
      color: primary.lightBlue,
    },
  },
  mainContainer: {
    width: 354,
    borderRadius: 10,
  },
  headerContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: 20,
  },
  userNameContainer: {
    flexDirection: 'column',
    alignSelf: 'center',
    padding: 20,
  },
  mailText: {
    color: grey.heading,
    fontSize: 14,
  },
  userText: {
    color: grey.heading,
    fontSize: 14,
    fontWeight: 700,
  },
  listContainer: {
    padding: 20,
  },
  footerContainer: {
    alignItems: 'center',
  },
  footerMenu: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    paddingTop: 15,
  },
  footerText: {
    color: grey.heading,
    fontSize: 14,
    paddingLeft: 15,
    '&:hover': {
      color: primary.lightBlue,
    },
  },
});

type OptionsModalProps = {
  logout: () => void;
};

export const UserCard = ({ logout }: OptionsModalProps) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const classes = useStyles();
  const { userInfo, selectedRole } = useRolesContext();
  const handleMenu = (event: AnyObject) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Grid>
      <IconButton
        style={{ height: '100%' }}
        aria-label="change language"
        aria-controls="menu-appbar"
        aria-haspopup="true"
        onClick={handleMenu}
      >
        <Grid container direction="row" alignItems="center">
          <Grid item>
            <UserAvatar
              rol={selectedRole.roleName}
              name={userInfo?.name}
              avatarWidth={50}
              avatarHeight={50}
            />
          </Grid>
          <Grid item>
            <KeyboardArrowDownOutlinedIcon fontSize="large" />
          </Grid>
        </Grid>
      </IconButton>

      <Menu
        id="menu-appbar-language"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        classes={{ paper: classes.mainContainer }}
        open={!!anchorEl}
        onClose={handleClose}
      >
        <Grid className={classes.headerContainer}>
          <UserAvatar
            rol={selectedRole.roleName}
            name={userInfo?.name}
            avatarWidth={50}
            avatarHeight={50}
          />
        </Grid>

        <Divider />

        <Grid container justify="center">
          <IconButton className={classes.footerMenu} onClick={logout}>
            <LockOpenOutlinedIcon fontSize="large" />
            <Typography className={classes.footerText}> Cerrar Sesión </Typography>
          </IconButton>
        </Grid>
      </Menu>
    </Grid>
  );
};
