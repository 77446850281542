import axios from 'axios';
import { AnyObject } from 'src/commons';
import { API_URL } from '../../config';

const Axios = axios.create({ baseURL: API_URL });

Axios.defaults.headers.post['Content-Type'] = 'application/json';

export { Axios };

class AxiosRequest {
  async get(url: string) {
    try {
      const result = await Axios.get(url);
      return result;
    } catch (error) {
      if (error.message.includes(412)) window.location.reload();
      throw error;
    }
  }

  async post(url: string, data: AnyObject) {
    try {
      const result = await Axios.post(url, data);
      return result;
    } catch (error) {
      if (error.status === 412) window.location.reload();
      throw error;
    }
  }
}
const i = new AxiosRequest();
export { i as AxiosRequest };
