import { useState, useEffect, useCallback, useMemo } from 'react';
import { AxiosRequest } from 'src/lib/axios/Axios';

export const useFetch = <T>(url: string, query?: string) => {
  const [data, setData] = useState<T | null>(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  const fetch = useCallback(
    async (newQueryParams?: string) => {
      setLoading(true);
      setData(null);
      setError('');
      const urlReq = `${url}?${newQueryParams || query}`;
      try {
        const res = await AxiosRequest.get(urlReq);
        setLoading(false);
        setData(res.data);
        return res;
      } catch (err) {
        setLoading(false);
        setError('An error ocurred');
        return { data: [] };
      }
    },
    [url, query],
  );

  useEffect(() => {
    fetch();
  }, [fetch]);

  const reFetch = useMemo(() => fetch, [fetch]);

  return { data, loading, error, reFetch };
};
