import {
  Box,
  Card,
  CardContent,
  Divider,
  Grid,
  IconButton,
  Link,
  Typography,
} from '@material-ui/core';
import React from 'react';
import GetAppIcon from '@material-ui/icons/GetApp';

import {
  DataPersonalCreditDocentAlumnCreditDetailType,
  DataPersonalCreditDocentAlumnCreditType,
  ImagePersonalCreditType,
} from '../../../type/orderDetail';
import { footerStyles } from '../../../styles/orderDetails';

interface DataPersonalCreditAlumnDocente {
  dataDetail: DataPersonalCreditDocentAlumnCreditType;
}

export const DataPersonalCreditAlumnDocente = ({
  dataDetail: { title, data, documents },
}: DataPersonalCreditAlumnDocente) => {
  const classes = footerStyles();
  return (
    <Box width="100%">
      <Box mb={2}>
        <Typography variant="h4" className={classes.titlePersonal}>
          {title}
        </Typography>
      </Box>
      <Card>
        <CardContent>
          <Grid container xs={12} direction="row">
            <Grid
              item
              spacing={3}
              xs={12}
              sm={6}
              style={{ padding: '10px', width: '100%', marginBottom: '20px' }}
            >
              <Box mb={2}>
                <Typography
                  align="left"
                  variant="subtitle2"
                  className={classes.labelInputWeightBold}
                >
                  Información
                </Typography>
              </Box>

              <Grid item container xs={12} spacing={2}>
                {data.map(({ name, value }: DataPersonalCreditDocentAlumnCreditDetailType) => (
                  <Grid item xs={6} direction="column">
                    <Typography className={classes.fontWeight600}>{name}</Typography>
                    <Typography className={classes.fontBody2}>{value}</Typography>
                  </Grid>
                ))}
              </Grid>
            </Grid>
            <Grid
              item
              spacing={3}
              xs={12}
              sm={6}
              style={{ padding: '10px', width: '100%', marginBottom: '20px' }}
            >
              <Grid item xs={12}>
                <Typography
                  align="left"
                  variant="subtitle2"
                  className={classes.labelInputWeightBold}
                >
                  Documentos
                </Typography>
                <Grid
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    width: '100%',
                    overflowY: 'scroll',
                  }}
                />
                {documents?.map((item: ImagePersonalCreditType) => (
                  <>
                    <Grid
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        width: '100%',
                        alignItems: 'center',
                      }}
                    >
                      <Typography className={classes.documentName}>{item.name}</Typography>
                      <Grid style={{ display: 'flex' }}>
                        <Link target="_blank" href={item.result}>
                          <IconButton>
                            <GetAppIcon />
                          </IconButton>
                        </Link>
                      </Grid>
                    </Grid>
                    <Divider />
                  </>
                ))}
              </Grid>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Box>
  );
};
