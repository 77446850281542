import React, { useCallback } from 'react';
import { DatabaseConnector } from '@phinxlab/libby-rest-web';
import { LibbyObject } from 'src/commons';
import { Grid, makeStyles } from '@material-ui/core';
import { nubesBackground } from 'src/assets/images';
import { primary } from 'src/theme/colors';
import { useSnackbar } from 'notistack';
import { GoogleApi } from '../../../app/services';
import { LoginForm } from './components/LoginForm';
import { useProfileContext } from '../../../lib/profiles/ProfileContext';
import { API_URL } from '../../../config';
import { Axios } from '../../../lib/axios';
import { useRolesContext } from '../../../context';

const LoginStyles = makeStyles((theme) => ({
  container: {
    width: '100%',
    height: '100%',
    background: 'linear-gradient(180deg, #417AB6 0%, #6AA7D0 77.08%, #86C6E3 100%)',
    padding: '25px 25px 10px 25px',
  },
  leftContainer: {
    width: '65%',
    height: '100%',
    backgroundImage: `url(${nubesBackground.nubes})`,
    backgroundPosition: '0% 70%',
    backgroundSize: 'cover',
  },
  formContainer: {
    height: '100%',
    paddingLeft: 20,
  },
  iconContainer: {
    paddingLeft: 40,
    marginTop: theme.spacing(10),
  },
  descriptionTextTitle: {
    fontSize: 30,
    fontFamily: 'Open Sans',
    fontWeight: 600,
    paddingLeft: 30,
    color: 'white',
    marginBottom: theme.spacing(2),
  },
  descriptionText: {
    fontSize: 15,
    fontFamily: 'Open Sans',
    fontWeight: 600,
    paddingLeft: 30,
    color: 'white',
  },
  CardContainer: {
    flexDirection: 'row',
    width: '100%',
    marginTop: 50,
    alignSelf: 'center',
    justifySelf: 'center',
    alignContent: 'center',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'red',
  },
  BuenosAiresCiudadLogin: {
    paddingLeft: 30,
  },
  bottom: {
    marginTop: '-11%',
  },
  icon: {
    fontSize: 45,
    color: primary.white,
  },
}));
const HomePublicRaw = ({ libby }: LibbyObject) => {
  const { setUserInfo, setSelectedRole } = useRolesContext();
  const { setProfile } = useProfileContext();
  const { enqueueSnackbar } = useSnackbar();
  const classes = LoginStyles();
  const loginAction = useCallback(
    async (username, password) => {
      try {
        const res = await fetch(`${API_URL}/login`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            username,
            password,
          }),
        });
        Axios.defaults.headers.common['X-JustRPC-Token'] = res.headers.get('X-JustRPC-Token');
        const json = await res.json();
        setUserInfo(json.user);
        const rol = json.user.groups[0];
        setSelectedRole({
          roleUserId: rol.id,
          roleName: rol.name,
        });
        localStorage.setItem('X-JustRPC-Token', res.headers.get('X-JustRPC-Token') || '');
        if (res.status === 200) {
          setProfile('private');
        } else {
          enqueueSnackbar(`El usuario ingresado no es válido. Intentalo nuevamente.`, {
            variant: 'error',
          });
        }
      } catch (e) {
        // eslint-disable-next-line
        console.log(e);
        enqueueSnackbar(`El usuario ingresado no es válido. Intentalo nuevamente.`, {
          variant: 'error',
        });
      }
    },
    [setProfile, setUserInfo, setSelectedRole, enqueueSnackbar],
  );

  const googleSignIn = useCallback(async () => {
    try {
      const token = await GoogleApi.singIn();
      const res = await libby.session.login('google', 'sign in', {
        provider: 'google',
        id_token: token,
      });
      if (!res) {
        enqueueSnackbar(`Usuario invalido`, {
          variant: 'error',
        });
      }
    } catch (e) {
      console.log(e);
      if (e.message === 'Dominio no permitido') {
        enqueueSnackbar(e.message, {
          variant: 'error',
        });
      } else {
        enqueueSnackbar('Ah ocurrido un error al intentar iniciar sesion con google', {
          variant: 'error',
        });
      }
    }
  }, [libby.session, enqueueSnackbar]);

  return (
    <Grid container justify="center" alignItems="center" style={{ height: '800px' }}>
      <Grid>
        <LoginForm
          handleLogin={(user, password) => loginAction(user, password)}
          onGoogleSignInClick={googleSignIn}
        />
      </Grid>
    </Grid>
  );
};

export const HomePublic = DatabaseConnector(HomePublicRaw)();
