import { Box, Grid } from '@material-ui/core';
import moment from 'moment';
import React, { useMemo } from 'react';
import { OperationLogs } from 'src/app/models/business/Operation';
import { InfoTable, Loading, useFetch } from 'src/commons';
import { Order } from '../../../../../app/models/business/Order';

interface OrderLogsOperationProps {
  order: Order;
}

export const OrderLogsOperation = ({ order }: OrderLogsOperationProps) => {
  const { data, loading, reFetch } = useFetch<OperationLogs[]>(
    `/operation/logs`,
    `operation_id=${order.operation?.operation_order_id}`,
  );

  const dataLogs = useMemo(() => data || [], [data]);

  const columns = useMemo(
    () => [
      {
        id: 'user',
        label: 'Usuario',
        hideSortIcon: true,
        width: '15%',
        render: (value: string, row: OperationLogs) => row.updated_by?.[0]?.username,
      },
      {
        id: 'date',
        label: 'Fecha de cambio',
        hideSortIcon: true,
        width: '25%',
        render: (value: number, row: OperationLogs) =>
          moment(row.updated_at).format('DD-MM-YYYY hh:mm:ss'),
      },
      {
        id: 'action',
        label: 'Acción realizada',
        width: "60%",
        hideSortIcon: true, 
        render: (value: number, row: OperationLogs) => row.comment_change,
      },
    ],
    [],
  );

 
  return (
    <Grid container style={{ paddingBottom: '10px' }}>
      <Grid container style={{ padding: '24px', width: '100%' }}>
        <Box width="100%">
          {loading ? <Loading /> : <InfoTable columns={columns} rows={dataLogs} />}
        </Box>
      </Grid>
    </Grid>
  );
};
