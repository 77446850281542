import React, { useState } from 'react';
import { Grid, FormControlLabel, Checkbox, makeStyles } from '@material-ui/core';
import { MainInput, MainButton } from 'src/commons';
import { primary } from 'src/theme/colors';

interface LoginFormProps {
  handleLogin: (user: string, password: string) => void;
  onGoogleSignInClick: () => void;
}

const LoginFormStyles = makeStyles({
  container: {
    backgroundColor: 'white',
    paddingLeft: 35,
    paddingRight: 35,
    width: '80%',
    paddingTop: 20,
    borderRadius: 15,
    paddingBottom: 35,
    marginBottom: 25,
    marginTop: '30%',
    marginRight: '15%',
  },
  header: {
    alignItems: 'center',
    width: '100%',
    height: 25,
  },
  icon: {
    marginLeft: 10,
    marginRight: 10,
    height: 45,
  },
  formContainer: {
    marginTop: -100,
  },
  titleText: {
    marginTop: '30%',
    fontSize: 24,
    fontWeight: 600,
    fontFamily: 'Open Sans',
  },
  descriptionText: {
    marginTop: 15,
    fontSize: 13,
    fontFamily: 'Open Sans',
    fontWeight: 600,
  },
  descriptionContainer: {
    paddingTop: 25,
  },
  subDescripcion: {
    cursor: 'pointer',
    marginLeft: 4,
    color: primary.lightBlue,
    textDecoration: 'underline',
  },
  labelTitle: {
    color: 'red',
  },
  googleSignInButton: {
    marginTop: 20,
    marginBottom: 20,
  },
});

export const LoginForm = ({ handleLogin, onGoogleSignInClick }: LoginFormProps) => {
  const [user, setUser] = useState('');
  const [password, setPassword] = useState('');
  const classes = LoginFormStyles();
  const onSubmit = (e: { preventDefault: () => void }) => {
    e.preventDefault();
    handleLogin(user, password);
  };
  return (
    <Grid container direction="column">
      <Grid justify="center">
        <span className={classes.titleText}>Iniciar sesión</span>
        <Grid>
          <form onSubmit={onSubmit}>
            <MainInput
              fullWidth
              customStyle={{ marginTop: 25, maxWidth: 284 }}
              value={user}
              handleChange={(e) => setUser(e.target.value)}
              label="Usuario"
              placeholder="Ingresá tu usuario"
              customStyleLabel={{
                fontWeight: 600,
                marginBottom: -15,
                marginTop: '10%',
              }}
            />
            <MainInput
              fullWidth
              customStyle={{ marginTop: 25, maxWidth: 284 }}
              label="Contraseña"
              type="password"
              value={password}
              handleChange={(e) => setPassword(e.target.value)}
              placeholder="Ingresá tu contraseña"
              customStyleLabel={{ fontWeight: 600, marginBottom: -15, marginTop: '10%' }}
            />
            <FormControlLabel
              style={{ marginTop: 15 }}
              control={<Checkbox color="primary" aria-label="recuerdame" />}
              label="Recuerdame"
            />
            <MainButton
              customStyle={{ marginTop: 15, width: 170 }}
              title="Ingresar"
              type="primary"
              size="medium"
              typeButton="submit"
            />
          </form>
        </Grid>
      </Grid>
    </Grid>
  );
};
