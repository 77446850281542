import { makeStyles } from '@material-ui/styles';
import pallet from 'src/theme/palette';

export const useStyles = makeStyles({
    rootRadio: {
      display: 'flex',
      justifyContent: 'space-around',
      width: '100%'
    },
    rootMessage: {
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
    },
    textField: {
      marginBottom: '20px'
    },
    inputField: {
      display: 'none'
    },
    label: {
      display: 'flex',
      height: '40px',
      width: '100%',
      justifyContent: 'flex-end'
    },
    labelInput: {
      marginBottom: 10,
      fontSize: 16,
      lineHeight: '17px',
      fontWeight: 400,
      color: pallet.text.primaryAlternative,
    },
    inputText: {
      marginLeft: '20px'
    },
    actions: {
      display: 'flex',
      justifyContent: 'flex-end'
    },
    attachments: {
      display: 'flex', 
      flexDirection: 'column', 
      width: '100%', 
      overflowY: 'scroll',
      border: '1px solid #D9D9D9',
      borderRadius: '4px',
      padding: '10px',
      minWidth: '200px',
      height: '100%'
    },
    editAttachments: {
      display: 'flex', 
      width: '50%', 
      justifyContent: 'flex-end'
    },
    innerAttachments: {
      display: 'flex', 
      justifyContent: 'space-between', 
      width: '100%', 
      alignItems: 'center' ,
      minWidth: '300px'
    }
  });