import { Order } from '../../../../app/models/business/Order';

interface UseTipoPrestamoProps {
  order: Order
}

enum TipoPrestamo {
  SOLO_FIRMA = "A sola firma",
  GARANTIA_PAGO =  "Con garantia de pago",
  ALUMNO_DOCENTE = "Alumno o Docente"
}

export const useTipoPrestamo = ({ order }: UseTipoPrestamoProps): TipoPrestamo | string => {
  let tipoPrestamo = "";

  const { payment } = order;
  const applicantInfo = payment.metadata?.applicantInfo;
  const guarantorInfo = payment.metadata?.guarantorInfo;
  const docentAlumnInfo = payment.metadata?.docentAlumnInfo;

  if (applicantInfo && guarantorInfo) {
    tipoPrestamo = TipoPrestamo.GARANTIA_PAGO;
  } else if (applicantInfo && !guarantorInfo && !docentAlumnInfo) {
    tipoPrestamo = TipoPrestamo.SOLO_FIRMA;
  } else if (docentAlumnInfo && !applicantInfo && !guarantorInfo) {
    tipoPrestamo = TipoPrestamo.ALUMNO_DOCENTE;
  }

  return tipoPrestamo;
};
