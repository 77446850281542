import {
  makeStyles,
} from '@material-ui/core';
import { grey, primary } from 'src/theme/colors';
import pallet from '../../../../theme/palette';

export const footerStyles = makeStyles(() => ({
  containerFooter: {
    background: primary.white,
    padding: '10px 20px',
    boxShadow: '0px -7px 3px rgba(0, 0, 0, 0.1);',
  },
  labelInput: {
    fontSize: 16,
    lineHeight: '17px',
    fontWeight: 400,
    color: pallet.text.primaryAlternative,
  },
  link: {
    width: '100%',
    marginTop: '10px',
  },
  labelInputWeightBold: {
    marginBottom: 10,
    fontSize: 16,
    lineHeight: '17px',
    fontWeight: 'bold',
    fontFamily: 'Open Sans',
    color: pallet.text.primaryAlternative,
  },
  fontWeight600: {
    fontWeight: 600,
    fontFamily: 'Open Sans',
  },
  fontBody2: {
    fontFamily: 'Open Sans',
  },
  titlePersonal: {
    color: '#223092',
    fontFamily: 'Open Sans',
    fontWeight: 700,
  },
  documentName: {
    fontFamily: 'Open Sans',
    fontWeight: 400,
    lineHeight: '20.02px',
  },
}));
