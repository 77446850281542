export const PROMOTION_SLUG: { [k: string]: number } = {
  'novatech-vueltaalcole': 1,
  'bancoprovincia-vueltaalcole': 2,
  'correocompras-vueltaalcole': 3,
}

export const PROMOTION_ID_SLUG: { [k: number]: string } = {
  1: 'novatech-vueltaalcole',
  2: 'bancoprovincia-vueltaalcole',
  3: 'correocompras-vueltaalcole',
};
