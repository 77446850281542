import { Orders } from 'src/screens/Private/Orders';
import { MenuItem } from '../../../../commons/ui/menu';

export const routes: MenuItem[] = [
  {
    key: 'promotionsOperationsMain',
    basePath: '/',
    target: {
      component: Orders,
      path: '/',
    },
    enabled: true,
    icon: 'List',
    text: 'PromotionOperation',
    children: [],
    default: true,
  },
];
