import React, { useCallback, useMemo, useState, useEffect, useRef } from 'react';
import { Grid, Typography, IconButton, Box } from '@material-ui/core';
import GetAppIcon from '@material-ui/icons/GetApp';
import { OperationStatus } from 'src/app/models/business/OperationStatus';
import { OptionsObject, SnackbarKey, SnackbarMessage } from 'notistack';
import customFormDialog from 'src/commons/services/customFormDialog';
import { AttachmentField } from 'src/commons/components/AttachmentField';
import { MARKETPLACE } from 'src/commons/const/marketplace';
import { PUBLICATION_URL } from 'src/commons/const/publicationUrls';
import { AxiosRequest } from 'src/lib/axios/Axios';
import { OperationOrderGateway } from 'src/app/models/business/OpertationOrderGateway';
import { FormRadioNoFormOption } from 'src/lib/templates/components/FormRadioNoForm';
import {
  confirmDialog,
  InfoTable,
  isCatamarcaUser,
  Loading,
  MainButton,
  MainInput,
  NOT_EDIT_OPERATION_ORDER_STATE,
  OPERATION_ORDER_STATE,
  SimpleSelect,
  useTranslation,
} from '../../../../../commons';
import pallet from '../../../../../theme/palette';
import { API_URL } from '../../../../../config';
import { Order } from '../../../../../app/models/business/Order';
import { UserInfo, UserRole } from '../../../../../context';
import { FileTypeOperation, Operation } from '../../../../../app/models/business/Operation';
import { PaymentIDInput } from '../PaymentIDInput/PaymentIDInput';
import { usePaymentIDInputs } from '../../hooks/usePaymentIDInputs';
import { DeliveryInfo, PaymentIdInfo } from './components';

import { DataPersonalCreditAlumnDocente } from './components/dataPeronalCreditAlumnDocente';
import {
  DataPersonalCreditDocentAlumnCreditType,
  OrderDetailContextType,
} from '../../type/orderDetail';
import { footerStyles } from '../../styles/orderDetails';
import { useItemDetail } from '../../hooks/useItemDetail';
import { OrderDetailContext } from '../../context';
import { useAllPersonalCreditInfo } from '../../hooks/useAllPersonalCreditInfo';
import { useStatusPayment } from '../../hooks/useStatusPayment';
import { useTipoPrestamo } from '../../hooks/useTipoPrestamo';

interface EditPromotionOperationProps {
  order: Order;
  view?: boolean;
  userInfo: UserInfo;
  userRole: UserRole;
  statusPayments: OperationStatus[];
  updateOrder?: (order_id: string, order: Order) => void;
  notistick: (message: SnackbarMessage, options?: OptionsObject | undefined) => SnackbarKey;
  reLoad: () => void;
  operationOrderGateway: FormRadioNoFormOption[];
}

const customStyleInput = { height: 100, width: '100%' };

export const OrderDetailView = ({
  order,
  view = false,
  userInfo,
  userRole,
  statusPayments,
  updateOrder,
  notistick,
  reLoad,
  operationOrderGateway,
}: EditPromotionOperationProps) => {
  const { t } = useTranslation();
  const isRolCatamarca = isCatamarcaUser(userRole.roleUserId.toString());

  const statusPaymentsEnabledWithMarketplace = useStatusPayment({ order, statusPayments });

  const [statusId, setStatusId] = useState<string>(order.payment.payment_state.payment_state_id);

  const firstMount = useRef<boolean>(true);
  const [comments, setComments] = useState(order.operation?.comments || '');
  const [attachments, setAttachments] = useState<FileTypeOperation[]>([]);

  useEffect(() => {
    setAttachments(order.operation?.attachments || []);
  }, [order.operation?.attachments]);

  const [loading, setLoading] = useState(false);
  const [disabledAssignTome, setDisabledAssignTome] = useState(
    userRole.roleUserId === '1'
      ? order.operation?.assigned_to.account_id === userInfo?.id
      : !!order.operation,
  );
  const [dataOrder, setDataOrder] = useState<Order>(order);
  const {
    inputValues: paymentIdInputValues,
    addInput: addPaymentIdInput,
    removeInput: removePaymentIdInput,
    onChangeInput: onChangePaymentIdInput,
    setValues: setValuesIdInputs,
    loading: loadingPaymentIdInputs,
    setLoading: setLoadingPaymentIdInputs,
  } = usePaymentIDInputs();

  const isCreditPayment = dataOrder.payment.reference === 'credit';

  const changedPaymentIds = useMemo(() => {
    const paymentIdValues = paymentIdInputValues
      .filter((value) => value.value)
      .map((value) => value.value);
    const operationPaymentIdValues = order.operation?.link_payments || [];
    return (
      JSON.stringify(paymentIdValues) !== JSON.stringify(operationPaymentIdValues) ||
      (operationPaymentIdValues?.length && !paymentIdValues?.length)
    );
  }, [order.operation, paymentIdInputValues]);

  const initPaymentIdInputs = useCallback(() => {
    if (changedPaymentIds && firstMount.current) {
      setValuesIdInputs(order.operation?.link_payments || ['']);
      firstMount.current = false;
    }
    setLoadingPaymentIdInputs(false);
  }, [order.operation, setValuesIdInputs, changedPaymentIds, setLoadingPaymentIdInputs]);

  useEffect(() => {
    initPaymentIdInputs();
  }, [initPaymentIdInputs]);

  const [currentOperation, setCurrentOperation] = useState<Operation | null>(null);

  const disableOrderState = useMemo(
    () =>
      !!(statusPaymentsEnabledWithMarketplace || []).find(
        (item) =>
          item.operation_order_state_id === dataOrder.payment.payment_state.payment_state_id,
      )?.is_last_value,
    [statusPaymentsEnabledWithMarketplace, dataOrder.payment.payment_state.payment_state_id],
  );

  const isInvalidToApprove = useMemo(() => {
    return (
      NOT_EDIT_OPERATION_ORDER_STATE.includes(statusId as OPERATION_ORDER_STATE) &&
      dataOrder?.payment?.reference !== 'credit' &&
      (!attachments?.length || !paymentIdInputValues?.length)
    );
  }, [statusId, attachments, paymentIdInputValues, dataOrder]);

  const classes = footerStyles();

  const [gatewayId, setGatewayId] = useState<string | null>(
    order.operation?.payment_gateway?.payment_gateway_id
      ? order.operation?.payment_gateway.payment_gateway_id?.toString()
      : dataOrder.payment_gateway?.payment_gateway_id
      ? dataOrder.payment_gateway?.payment_gateway_id?.toString()
      : null,
  );

  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>, value: string): void => {
      setGatewayId(value);
    },
    [],
  );

  const isInvalidToGateway = useMemo(
    () => (!!paymentIdInputValues.length && gatewayId !== null) || !paymentIdInputValues.length,
    [gatewayId, paymentIdInputValues],
  );

  const disabledSaveButton = useMemo(() => {
    const validateAssigned = userInfo?.id === dataOrder.operation?.assigned_to.account_id;

    return !validateAssigned || disableOrderState || isInvalidToApprove || !isInvalidToGateway;
  }, [userInfo, dataOrder.operation, disableOrderState, isInvalidToApprove, isInvalidToGateway]);

  const onChangeOrder = useCallback(
    (orderUpdated: Order) => {
      if (updateOrder) updateOrder(orderUpdated.order_id, orderUpdated);
      setDataOrder(orderUpdated);
    },
    [updateOrder],
  );

  const changeOrderState = useCallback(async () => {
    setLoading(true);
    if (!statusId) {
      return notistick(`Por favor seleccione el estado de la orden`, {
        variant: 'error',
      });
    }
    const data = {
      marketplace_id: dataOrder.marketplace.marketplace_id,
      order_id: dataOrder.order_id,
      comments,
      payment_state_id: statusId,
      operation_order_id:
        dataOrder.operation?.operation_order_id || currentOperation?.operation_order_id,
      attachments,
      link_payments: paymentIdInputValues
        .filter((value) => value.value)
        .map((value) => value.value),
      payment_gateway_id: gatewayId,
    };

    try {
      const response = await AxiosRequest.post('/operation/status', data);
      if (updateOrder) {
        const statusUpdate = (statusPaymentsEnabledWithMarketplace || []).find(
          (status) => status.operation_order_state_id.toString() === statusId.toString(),
        );

        const orderUpdate: Order = {
          ...dataOrder,
          payment: {
            ...dataOrder.payment,
            payment_state:
              {
                payment_state_id: statusUpdate?.operation_order_state_id || '',
                name: statusUpdate?.name || '',
              } || dataOrder.payment.payment_state,
          },
          operation: { ...dataOrder.operation, ...response.data },
        };
        onChangeOrder(orderUpdate);
      }
      notistick(`Se cambio el estado de la orden`, {
        variant: 'success',
      });
    } catch (error) {
      notistick(`${error.response.data}`, {
        variant: 'error',
      });
    } finally {
      setLoading(false);
      customFormDialog.handleCancel();
      reLoad();
    }
  }, [
    dataOrder,
    comments,
    statusId,
    updateOrder,
    statusPaymentsEnabledWithMarketplace,
    currentOperation,
    notistick,
    onChangeOrder,
    reLoad,
    attachments,
    paymentIdInputValues,
    gatewayId,
  ]);

  const getRowHref = useCallback(
    (row) => {
      if (dataOrder.marketplace.marketplace_id === MARKETPLACE.ENOVA) {
        return `${PUBLICATION_URL.ENOVA}/product/${row.publication_id}`;
      }
      if (dataOrder.marketplace.marketplace_id === MARKETPLACE.NOVASTORE) {
        return `${PUBLICATION_URL.NOVASTORE}/product/${row.publication_id}`;
      }
      if (dataOrder.marketplace.marketplace_id === MARKETPLACE.DETECNOLOGIA) {
        return `${PUBLICATION_URL.DETECNOLOGIA}/product/${row.publication_id}`;
      }
      return '#';
    },
    [dataOrder],
  );

  const { columns } = useItemDetail({ getRowHref });

  const handleAssigneMe = useCallback(async () => {
    if (userRole.roleUserId === '1' && dataOrder.operation) {
      const confirm = await confirmDialog.show({
        title: '',
        content: `La orden ${dataOrder.order_id} se encuentra asignada a ${dataOrder.operation?.assigned_to.username}. ¿Desea asignarse usted esta orden? `,
        confirmText: 'Confirmar',
        cancelText: 'Cancelar',
      });
      if (!confirm) return;
    }

    setLoading(true);
    const data = {
      order_id: dataOrder.order_id,
      comments,
      marketplace_id: dataOrder.marketplace.marketplace_id,
      operation_order_id: dataOrder.operation?.operation_order_id,
    };
    try {
      const res = await AxiosRequest.post(`${API_URL}/operation/assigned`, data);

      const assigned_to = { account_id: userInfo?.id, username: userInfo?.name };
      const operation = dataOrder.operation
        ? {
            ...dataOrder.operation,
            assigned_to,
            comments,
            operation_order_id: res.data.operation_order_id,
          }
        : { assigned_to, comments, operation_order_id: res.data.operation_order_id };
      // @ts-ignore
      const orderUpdate: Order = { ...dataOrder, operation };
      onChangeOrder(orderUpdate);
      setCurrentOperation(res.data);
      setDisabledAssignTome(true);
      notistick(`La orden fue asignada correctamente!`, {
        variant: 'success',
      });
    } catch (err) {
      const error = err.response.data.split(': ');
      notistick(`${t(error[1])}`, {
        variant: 'error',
      });
    } finally {
      setLoading(false);
    }
  }, [dataOrder, comments, userRole.roleUserId, notistick, userInfo, onChangeOrder, t]);

  const isOrderAssigned = userInfo?.id !== dataOrder.operation?.assigned_to.account_id;
  const contextValue = useMemo<OrderDetailContextType>(
    () => ({
      order,
      paymentIdInputValues,
      addPaymentIdInput,
      removePaymentIdInput,
      onChangePaymentIdInput,
      loadingPaymentIdInputs,
      setLoadingPaymentIdInputs,
      disabled: disableOrderState,
    }),
    [
      order,
      paymentIdInputValues,
      addPaymentIdInput,
      removePaymentIdInput,
      onChangePaymentIdInput,
      loadingPaymentIdInputs,
      setLoadingPaymentIdInputs,
      disableOrderState,
    ],
  );

  const marketplaceName = useMemo(() => {
    const name = dataOrder.marketplace.name.toLowerCase();
    return name === 'novastore' ? 'novatechstore' : name;
  }, [dataOrder.marketplace.name]);

  const { allPersonalCredit } = useAllPersonalCreditInfo({ dataOrder });

  const tipoPrestamo = useTipoPrestamo({ order: dataOrder });

  return (
    <OrderDetailContext.Provider value={contextValue}>
      <Grid container style={{ paddingBottom: '10px' }}>
        <Grid container style={{ padding: '24px' }}>
          <Grid item xs={12}>
            <Grid container style={{ paddingBottom: '10px' }}>
              <Grid item container xs={12}>
                <Grid container justify="flex-end">
                  <Grid item xs={3}>
                    <Typography>
                      {`Asignado a: ${dataOrder.operation?.assigned_to.username || '-'}`}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              {dataOrder.delivery_type !== 'Shipment' ? (
                <></>
              ) : (
                <>
                  <InfoTable columns={columns} rows={dataOrder.order_items} />
                  <DeliveryInfo rows={[dataOrder.shipment_address]} />
                </>
              )}
            </Grid>
          </Grid>
          {loading && <Loading />}
          <Grid
            item
            spacing={3}
            xs={12}
            sm={6}
            style={{ padding: '10px', width: '100%', marginBottom: '20px' }}
          >
            <Grid item xs={12} style={{ marginBottom: '20px' }}>
              <Grid item>
                <Typography>Tipo de entrega:</Typography>
                <Typography style={{ paddingBottom: '30px' }}>{dataOrder.delivery_type}</Typography>
              </Grid>
              {view ? (
                <Grid item>
                  <Typography>Estado de la orden:</Typography>
                  <Typography style={{ paddingBottom: '10px' }}>
                    {dataOrder.payment.payment_state.name}
                  </Typography>
                </Grid>
              ) : (
                <SimpleSelect
                  value={statusId}
                  title="Estado de la orden"
                  placeholder="Seleccioná el estado"
                  handleChange={(e) => setStatusId(e.target.value as string)}
                  content={statusPaymentsEnabledWithMarketplace}
                  valueKey="operation_order_state_id"
                  labelKey="name"
                  disabled={disableOrderState}
                />
              )}
            </Grid>
            <Grid item xs={12} style={{ marginBottom: '10px' }}>
              {view ? (
                <Grid>
                  <Typography>Comentario: </Typography>
                  <Typography style={{ paddingBottom: '10px' }}>
                    {dataOrder.operation?.comments || 'No hay comentarios'}
                  </Typography>
                </Grid>
              ) : (
                <Grid
                  container
                  justify="flex-start"
                  alignItems="flex-start"
                  style={{ height: '100%' }}
                >
                  <Grid item style={{ width: '100%' }}>
                    <MainInput
                      label="Comentario"
                      customStyleLabel={{
                        marginBottom: 10,
                        fontSize: 16,
                        lineHeight: '17px',
                        fontWeight: 400,
                        color: pallet.text.primaryAlternative,
                      }}
                      rows={4}
                      customStyle={customStyleInput}
                      value={comments}
                      multiline
                      handleChange={(e) => setComments(e.target.value)}
                      placeholder="Agrega un comentario"
                      fullWidth
                    />
                    {!isOrderAssigned && (
                      <>
                        {!isCreditPayment && (
                          <PaymentIDInput
                            gatewayId={gatewayId}
                            handleChange={handleChange}
                            operationOrderGateway={operationOrderGateway}
                          />
                        )}
                        {isInvalidToApprove && (
                          <Typography style={{ color: 'red' }}>
                            Para aprobar o recuperar la orden es necesario un ID de pago y un
                            documento
                          </Typography>
                        )}
                      </>
                    )}
                  </Grid>
                </Grid>
              )}
            </Grid>

            <Grid item xs={12} style={{ marginBottom: '20px' }}>
              <Grid>
                <Typography>Tipo medio de pago: </Typography>
                <Typography style={{ paddingBottom: '10px' }}>
                  {dataOrder?.operation?.payment_gateway?.name ||
                    dataOrder?.payment_gateway?.name ||
                    'No hay tipo de medio de pago hasta al momento'}
                </Typography>
              </Grid>
            </Grid>

            <Grid item xs={12} style={{ marginBottom: '20px' }}>
              <PaymentIdInfo
                view={view}
                statusId={statusId}
                paymentIdInputValues={paymentIdInputValues}
                order_id={dataOrder.order_id}
                marketplaceName={marketplaceName}
                isRolCatamarca={isRolCatamarca}
                linkDelivery={dataOrder.operation?.link_delivery}
              />
            </Grid>

            {!isCreditPayment && (
              <Grid item xs={12} style={{ marginBottom: '20px' }}>
                <Grid>
                  <Typography>Estado Gateway: </Typography>
                  <Typography style={{ paddingBottom: '10px' }}>
                    {dataOrder?.payment?.metadata_response?.result ||
                      'No hay pago hasta al momento'}
                  </Typography>
                </Grid>
              </Grid>
            )}
            {tipoPrestamo && (
              <Grid item xs={12} style={{ marginBottom: '20px' }}>
                <Grid>
                  <Typography>Tipo Prestamo: </Typography>
                  <Typography style={{ paddingBottom: '10px' }}>{tipoPrestamo}</Typography>
                </Grid>
              </Grid>
            )}
          </Grid>
          <Grid
            item
            spacing={3}
            xs={12}
            sm={6}
            style={{ padding: '10px', width: '100%', marginBottom: '20px' }}
          >
            <Grid item xs={12} style={{ height: '100%' }}>
              {view ? (
                <>
                  <Typography align="left" variant="subtitle2" className={classes.labelInput}>
                    Documentos:
                  </Typography>
                  <Grid
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      width: '100%',
                      overflowY: 'scroll',
                      height: '155px',
                    }}
                  >
                    {attachments?.map((item) => (
                      <Grid
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          width: '100%',
                          alignItems: 'center',
                        }}
                      >
                        <Typography>{item.name}</Typography>
                        <Grid style={{ display: 'flex' }}>
                          <a target="_self" download={item.name} href={item.result}>
                            <IconButton>
                              <GetAppIcon />
                            </IconButton>
                          </a>
                        </Grid>
                      </Grid>
                    ))}
                  </Grid>
                </>
              ) : (
                <AttachmentField
                  value={attachments}
                  onChange={setAttachments}
                  height="100%"
                  isAsigned={isOrderAssigned}
                  disabled={disableOrderState}
                />
              )}
            </Grid>
          </Grid>
          {!!allPersonalCredit.length &&
            allPersonalCredit.map((dataDetail: DataPersonalCreditDocentAlumnCreditType) => (
              <Box pb={2}>
                <DataPersonalCreditAlumnDocente dataDetail={dataDetail} />
              </Box>
            ))}
          {!view && (
            <Grid item xs={12}>
              <Grid container spacing={0} className={classes.containerFooter}>
                <Grid item xs={4}>
                  <Grid item xs={4} container justify="flex-start" spacing={4}>
                    <Grid item>
                      <MainButton
                        title="Cerrar"
                        type="secondary"
                        handleOnClick={() => customFormDialog.handleCancel()}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={8}>
                  <Grid container justify="flex-end" spacing={4}>
                    <Grid item>
                      <MainButton
                        title="Asignarme"
                        handleOnClick={() => handleAssigneMe()}
                        disabled={disabledAssignTome || loading}
                      />
                    </Grid>
                    <Grid item>
                      <MainButton
                        title="Guardar"
                        handleOnClick={() => changeOrderState()}
                        disabled={disabledSaveButton}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          )}
        </Grid>
      </Grid>
    </OrderDetailContext.Provider>
  );
};
