import { FormControl, FormControlLabel, Radio, RadioGroup, Typography } from '@material-ui/core';
import React from 'react';

export interface FormRadioNoFormOption {
  id: any;
  name: string;
}

interface FormRadioNoFormProps {
  value: any;
  onChange: (event: React.ChangeEvent<HTMLInputElement>, value: string) => void;
  options: FormRadioNoFormOption[];
}

export const FormRadioNoForm = ({ value, onChange, options }: FormRadioNoFormProps) => {
  return (
    <FormControl component="fieldset">
      <RadioGroup
        row
        aria-label="gender"
        name="controlled-radio-buttons-group"
        value={value}
        onChange={onChange}
      >
        {options.map(({ id, name }) => (
          <FormControlLabel value={id} control={<Radio />} label={name} key={id} />
        ))}
      </RadioGroup>
    </FormControl>
  );
};
