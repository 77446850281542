import { useCallback, useEffect, useRef, useState } from 'react';
import { cloneDeep, Many, orderBy, uniqBy } from 'lodash';
import { DirectionOption, useFetch } from '../../../../commons';
import { Order } from '../../../../app/models/business/Order';
import { JoinedFilters } from '../components/PromotionOperationHeader/types';
import { Operation } from '../../../../app/models/business/Operation';

export const useOrdersData = () => {
  const page = useRef(1);
  const [orders, setOrders] = useState<Order[]>([]);
  const { data, loading, reFetch } = useFetch<Order[]>(`/order`, `page=1`);
  const [loadingMore, setLoadingMore] = useState(false);
  const [reLoadData, setReLoadData] = useState(false);
  const currentQueryParams = useRef('');
  const currentFilter = useRef<JoinedFilters | null>(null);
  const currentOrderBy = useRef<{ orderBy: string; direction: DirectionOption } | null>(null);
  const [debounce, setDebounce] = useState<NodeJS.Timeout | null>(null);

  const updateOrder = useCallback(
    (order_id: string, order: Order) => {
      const updatedOrders = cloneDeep(orders);
      const orderIndex = updatedOrders.findIndex((item: Order) => item.order_id === order.order_id);
      updatedOrders[orderIndex] = { ...updatedOrders[orderIndex], ...order };
      setOrders(updatedOrders);
    },
    [orders],
  );

  useEffect(() => {
    if (data && !loading && orders?.length === 0) {
      setOrders(data || []);
    }
  }, [loading, data, orders]);

  const loadMore = useCallback(async () => {
    if (loadingMore) return;
    if (debounce) {
      clearTimeout(debounce);
    }
    setLoadingMore(true);
    setDebounce(
      setTimeout(async () => {
        const { data: newData } = await reFetch(
          `page=${page.current + 1}&${currentQueryParams.current}`,
        );
        page.current += 1;
        setOrders((prevState) => {
          const mergeData = uniqBy([...prevState, ...newData], (item) => item.order_id);
          const filter = currentOrderBy.current?.orderBy
            ? currentOrderBy.current?.orderBy.includes('order')
              ? currentOrderBy.current?.orderBy.split('.')[1]
              : currentOrderBy.current?.orderBy.replace('_', '.')
            : 'created_at';
          return orderBy(
            mergeData,
            filter,
            (currentOrderBy.current?.direction as Many<boolean | 'asc' | 'desc'>) || 'desc',
          );
        });

        setLoadingMore(false);
      }, 250),
    );
  }, [reFetch, loadingMore, debounce]);
  const reLoad = useCallback(() => {
    if (reLoadData) return;
    if (debounce) {
      clearTimeout(debounce);
    }
    setReLoadData(true);
    setDebounce(
      setTimeout(async () => {
        const { data: newData } = await reFetch(`page=1&${currentQueryParams.current}`);
        page.current = 1;
        setOrders(newData || []);
        setReLoadData(false);
      }, 250),
    );
  }, [reLoadData, reFetch, debounce]);

  const onFilter = useCallback(
    async (filter: JoinedFilters) => {
      const filterChanged = JSON.stringify(currentFilter.current) !== JSON.stringify(filter);
      if (!filterChanged) return;
      currentFilter.current = filter;
      const query = [];
      const keys = Object.keys(filter);
      for (let i = 0; i < keys.length; i++) {
        if (filter[keys[i] as keyof typeof filter]) {
          query.push(
            `${keys[i]}=${encodeURIComponent(filter[keys[i] as keyof typeof filter] as string)}`,
          );
        }
      }

      page.current = 1;
      setLoadingMore(true);
      if (currentOrderBy.current && currentOrderBy.current.orderBy) {
        query.push(`orderBy=${currentOrderBy.current.orderBy}`);
        query.push(`direction=${currentOrderBy.current.direction}`);
      }
      currentQueryParams.current = query.join('&');
      try {
        const url = query.length ? `page=1&${query.join('&')}` : `page=1`;
        const { data: newData } = await reFetch(url);
        setOrders(newData);
        setLoadingMore(false);
      } catch (err) {
        setLoadingMore(false);
      }
    },
    [reFetch],
  );

  const onOrderByChange = useCallback(
    async (_orderBy: string, direction: DirectionOption) => {
      const orderByChanged =
        JSON.stringify(currentOrderBy.current) !== JSON.stringify({ orderBy, direction });
      if (!orderByChanged) return;
      currentOrderBy.current = { orderBy: _orderBy, direction };
      const query = currentQueryParams.current.split('&');
      if (_orderBy) {
        query.push(`orderBy=${_orderBy}`);
        query.push(`direction=${direction}`);
      }
      page.current = 1;
      setLoadingMore(true);
      currentQueryParams.current = uniqBy(query.reverse(), (value) => value.split('=')[0])
        .reverse()
        .join('&');

      try {
        const url = query.length ? `page=1&${currentQueryParams.current}` : `page=1`;
        const { data: newData } = await reFetch(url);
        setOrders(newData || []);
        setLoadingMore(false);
      } catch (err) {
        setLoadingMore(false);
      }
    },
    [reFetch],
  );

  const withLocalFilters = useCallback((myData) => {
    if (currentFilter.current?.gateway) {
      return myData.filter(
        (item: Operation) =>
          item.payment?.metadata_response?.status === currentFilter.current?.gateway,
      );
    }
    return myData;
  }, []);

  return {
    data: withLocalFilters(orders),
    updateOrder,
    loading,
    loadMore,
    loadingMore,
    onFilter,
    reLoad,
    onOrderByChange,
  };
};
