export enum ROLES {
  ADMINISTRATOR = 1,
  USUARIO,
  USERCATAMARCA,
  ADMINCATAMARCA,
  CONSULTAS,
}

export function isCatamarcaUser(rol_id: string) {
  const rolId = parseInt(rol_id, 10);
  const rolesEnableCatamarca = [ROLES.ADMINCATAMARCA, ROLES.USERCATAMARCA];
  return rolesEnableCatamarca.includes(rolId);
}
