import ReactGA from "react-ga";

export interface SendEventParams { category: string, action: string, label: string, nonInteraction?:boolean, value?: number}

class GoogleAnalytic {

  initialize() {
    ReactGA.initialize('UA-190202452-1')
  }

  sendEvent({category, action, label, nonInteraction, value}: SendEventParams) {
    return ReactGA.event({
      category, action, label, nonInteraction, value,
    });
  }

}

const i: GoogleAnalytic = new GoogleAnalytic();
export { i as GoogleAnalytic };
