import React from 'react';
import { Grid, Link, Typography } from '@material-ui/core';
import { STER_URL } from 'src/config';
import { NOT_EDIT_OPERATION_ORDER_STATE, OPERATION_ORDER_STATE } from '../../../../../../commons';
import { PaymentIdInputValue } from '../../../hooks/usePaymentIDInputs';

interface PaymentIdInfoProps {
  view?: boolean;
  statusId: string;
  marketplaceName: string;
  order_id: string;
  paymentIdInputValues: Array<PaymentIdInputValue>;
  isRolCatamarca: boolean;
  linkDelivery?: string | null;
}

export const PaymentIdInfo = ({
  view,
  statusId,
  marketplaceName,
  order_id,
  paymentIdInputValues,
  isRolCatamarca,
  linkDelivery,
}: PaymentIdInfoProps) => {
  const validateState = NOT_EDIT_OPERATION_ORDER_STATE.includes(statusId as OPERATION_ORDER_STATE);
  
  return (
    <Grid item>
      <Typography>Links:</Typography>
      {!isRolCatamarca ? (
        <>
          {view ? (
            <>
              {paymentIdInputValues.map((item) => (
                <Typography>
                  <Link href={`${item.value}`} target="_blank">
                    {item.value}
                  </Link>
                </Typography>
              ))}
              {validateState ? (
                <Typography>
                  <Link
                    href={`${STER_URL}/orders?marketplace_name=${marketplaceName}&owner_id=${order_id}`}
                    target="_blank"
                  >
                    Ster
                  </Link>
                </Typography>
              ) : (
                <Typography style={{ paddingBottom: '10px' }}>No hay link de ster</Typography>
              )}
            </>
          ) : validateState ? (
            <Typography>
              <Link
                href={`${STER_URL}/orders?marketplace_name=${marketplaceName}&owner_id=${order_id}`}
                target="_blank"
              >
                Ster
              </Link>
            </Typography>
          ) : (
            <Typography style={{ paddingBottom: '10px' }}>No hay link de ster</Typography>
          )}
        </>
      ) : (
        <>
          {linkDelivery?.length ? (
            <Typography>
              <Link href={`${linkDelivery}`} target="_blank">
                Constancia de entrega
              </Link>
            </Typography>
          ) : (
            <Typography style={{ paddingBottom: '10px' }}>
              No hay link de constancia de entrega
            </Typography>
          )}
        </>
      )}
    </Grid>
  );
};
