import React, { useMemo, useCallback } from 'react';
import moment from 'moment';
import { useSnackbar } from 'notistack';
import EditIcon from '@material-ui/icons/Edit';
import ListAltIcon from '@material-ui/icons/ListAlt';
import VisibilityIcon from '@material-ui/icons/Visibility';
import { primary } from 'src/theme/colors';
import { OperationStatus } from 'src/app/models/business/OperationStatus';
import { Box, IconButton, makeStyles } from '@material-ui/core';
import { MARKETPLACE, ROLES } from 'src/commons/const';
import { OperationOrderGateway } from 'src/app/models/business/OpertationOrderGateway';
import { FormRadioNoFormOption } from 'src/lib/templates/components/FormRadioNoForm';
import {
  customFormDialog,
  OPERATION_ORDER_STATE,
  PAYMENT_REFERENCE,
  useFetch,
  isCatamarcaUser,
  NOT_EDIT_OPERATION_ORDER_STATE,
} from '../../../../commons';
import { InfoTableColumn } from '../../../../commons/types';
import { OrderDetailView } from '../components/OrderDetail';
import { Order } from '../../../../app/models/business/Order';
import { formatPrice } from '../../../../utils';
import { useRolesContext } from '../../../../context';
import { OrderLogsOperation } from '../components/OrderLogsOperation/OrderLogsOperation';

const useStyles = makeStyles(() => ({
  iconColor: { color: primary.paleBlueStrong },
  iconButton: {
    padding: 0,
  },
  iconDisabled: { color: primary.disabled },
}));

interface UseOrderColumnsProps {
  updateOrder: (order_id: string, order: Order) => void;
  reLoad: () => void;
}

const READ_ONLY_STATES = [
  OPERATION_ORDER_STATE.CS_YELLOW,
  OPERATION_ORDER_STATE.CS_RED,
  OPERATION_ORDER_STATE.CS_OTHER,
];

export const useOrderColumns = ({ updateOrder, reLoad }: UseOrderColumnsProps) => {
  const { userInfo, selectedRole } = useRolesContext();
  const { enqueueSnackbar } = useSnackbar();
  const rolesEnableCatamarca = isCatamarcaUser(selectedRole.roleUserId.toString());
  const classes = useStyles();
  const { data: orderStatusSource } = useFetch<OperationStatus[]>(
    '/operation/status',
    'available=true',
  );

  const { data: operationOrderGateway = [] } = useFetch<OperationOrderGateway[]>(
    '/operation/payment_gateway',
  );

  const hadlerViewOperation = useCallback(
    (row: Order) => {
      const statusPayments = orderStatusSource || [];
      const operationOrderGateways: FormRadioNoFormOption[] =
        operationOrderGateway?.map(
          ({ payment_gateway_id, name }: OperationOrderGateway) => ({    id: payment_gateway_id.toString(), name }),
        ) || [];

      if (
        !orderStatusSource?.find(
          (item) => item.operation_order_state_id === row.payment.payment_state.payment_state_id,
        )
      ) {
        statusPayments.push({
          operation_order_state_id: row.payment.payment_state.payment_state_id,
          name: row.payment.payment_state.name,
          available: false,
          is_last_value: false,
        });
      }
      customFormDialog.show({
        title: 'Detalle de la Orden',
        renderComponent: (
          <OrderDetailView
            order={row}
            userInfo={userInfo}
            userRole={selectedRole}
            view
            notistick={enqueueSnackbar}
            statusPayments={statusPayments}
            reLoad={reLoad}
            operationOrderGateway={operationOrderGateways}
          />
        ),
        sizeWidth: 'md',
      });
    },
    // TODO verify this
    [userInfo, orderStatusSource, selectedRole, enqueueSnackbar, reLoad, operationOrderGateway],
  );

  const hadlerEditOperation = useCallback(
    (row: Order) => {
      const statusPayments =
        orderStatusSource?.map((status) => ({
          ...status,
          disabled:
            READ_ONLY_STATES.findIndex((item) => item === status.operation_order_state_id) >= 0,
        })) || [];
      const operationOrderGateways: FormRadioNoFormOption[] =
        operationOrderGateway?.map(
          ({ payment_gateway_id, name }: OperationOrderGateway) => ({
            id: payment_gateway_id.toString(),
            name,
          }),
        ) || [];
      if (
        !orderStatusSource?.find(
          (item) => item.operation_order_state_id === row.payment.payment_state.payment_state_id,
        )
      ) {
        statusPayments.push({
          operation_order_state_id: row.payment.payment_state.payment_state_id,
          name: row.payment.payment_state.name,
          available: false,
          is_last_value: false,
          disabled:
            READ_ONLY_STATES.findIndex(
              (item) => item === row.payment.payment_state.payment_state_id,
            ) >= 0,
        });
      }
      customFormDialog.show({
        title: 'Editar Orden',
        renderComponent: (
          <OrderDetailView
            order={row}
            userInfo={userInfo}
            userRole={selectedRole}
            updateOrder={updateOrder}
            notistick={enqueueSnackbar}
            statusPayments={statusPayments}
            reLoad={reLoad}
            operationOrderGateway={operationOrderGateways}
          />
        ),
        sizeWidth: 'md',
      });
    },
    // TODO same, verify this
    [
      userInfo,
      updateOrder,
      orderStatusSource,
      selectedRole,
      enqueueSnackbar,
      reLoad,
      operationOrderGateway,
    ],
  );
  const handlerLogsOperation = useCallback((row: Order) => {
    customFormDialog.show({
      title: 'Historial Orden',
      renderComponent: <OrderLogsOperation order={row} />,
      sizeWidth: 'md',
    });
  }, []);

  return useMemo<InfoTableColumn<Order>[]>(
    () => [
      {
        id: 'payment.payment_id',
        label: 'ID Pago',
        width: '15%',
        render: (value, row) => row.payment.payment_id,
      },
      {
        id: 'order.order_id',
        label: 'ID Orden',
        width: '15%',
        render: (value, row) => `${row.order_id}`,
      },
      {
        id: 'marketplace_id',
        label: 'Marketplace',
        width: '5%',
        orderById: 'marketplace.name',
        render: (value, row) => row.marketplace.name,
      },
      {
        id: 'buyer.name',
        label: 'Comprador',
        width: '10%',
        render: (value, row) => `${row.buyer.name} ${row.buyer.lastname}`,
      },
      {
        id: 'buyer.document_number',
        label: 'Documento',
        width: '5%',
        render: (value, row) => `${row.buyer.document_number}`,
      },
      {
        id: 'buyer.email',
        label: 'Email',
        width: '5%',
        render: (value, row) => `${row.buyer.email}`,
      },
      {
        id: 'buyer.phone_number',
        label: 'Phone Number',
        width: '5%',
        render: (value, row) => `${row.buyer.phone_number}`,
      },
      {
        id: 'order.created_at',
        label: 'Fecha de Creacion',
        width: '18%',
        render: (value, row) => moment(row.created_at).format('DD-MM-YYYY hh:mm:ss'),
      },
      {
        id: 'order.total',
        label: 'Importe',
        width: '2%',
        render: (value, row) => formatPrice(row.total),
      },
      {
        id: 'payment_state.payment_state_id',
        label: 'Estado',
        width: '10%',
        render: (value, row) => row.payment.payment_state.name,
      },
      {
        id: 'assigned_to',
        label: 'Asignado',
        width: '10%',
        orderById: 'operation.assigned_to.username',
        render: (value, row) => row.operation?.assigned_to.username || 'No asignada',
      },
      {
        id: 'prmotion_id',
        label: 'Promoción',
        width: '10%',
        orderById: 'payment_promotion.name',
        render: (value, row) => row.payment.promotion?.name || 'N/A',
      },
      {
        id: 'icono',
        label: '',
        width: '5%',
        hideSortIcon: true,
        style: { textAlign: 'right' },
        render: (value: any, row) => {
          const disableEdit = NOT_EDIT_OPERATION_ORDER_STATE.includes(
            row.payment.payment_state.payment_state_id as OPERATION_ORDER_STATE,
          );

          return (
            <Box display="flex" width="92%">
              <Box p={1}>
                <IconButton className={classes.iconButton} onClick={() => hadlerViewOperation(row)}>
                  <VisibilityIcon className={classes.iconColor} />
                </IconButton>
              </Box>
              {((rolesEnableCatamarca &&
                row.marketplace.marketplace_id === MARKETPLACE.DETECNOLOGIA &&
                row.payment.reference === PAYMENT_REFERENCE.CREDIT) ||
                row.marketplace.marketplace_id !== MARKETPLACE.DETECNOLOGIA ||
                (row.marketplace.marketplace_id === MARKETPLACE.DETECNOLOGIA &&
                  row.payment.reference !== PAYMENT_REFERENCE.CREDIT)) && (
                  <Box p={1}>
                    <IconButton
                      disabled={disableEdit || (selectedRole.roleUserId === ROLES.CONSULTAS.toString())}
                      className={classes.iconButton}
                      onClick={() => hadlerEditOperation(row)}
                    >
                      <EditIcon className={disableEdit || (selectedRole.roleUserId === ROLES.CONSULTAS.toString()) ? classes.iconDisabled : classes.iconColor} />
                    </IconButton>
                  </Box>
              )}
              <IconButton className={classes.iconButton} onClick={() => handlerLogsOperation(row)}>
                <ListAltIcon className={classes.iconColor} />
              </IconButton>
            </Box>
          );
        },
      },
    ],
    [classes.iconButton, classes.iconColor, classes.iconDisabled, rolesEnableCatamarca, selectedRole.roleUserId, hadlerViewOperation, hadlerEditOperation, handlerLogsOperation],
  );
};
