export enum OPERATION_ORDER_STATE {
  APPROVED = 'abb0c62c-3555-4a85-9e4a-56c497860105',
  RECOVERED = '3600e0f9-9222-434d-a0cf-2194ac8615e4',
  CS_YELLOW = 'ed7d866b-d260-4949-a263-da55a0aeb63b',
  CS_RED = '34d447cb-c25c-442c-8341-da59cc1c07f8',
  CS_OTHER = '5413c830-e0f8-4073-b16c-2ad16c0a93ce',
  DELIVERED = '51785c1d-8890-4e48-b5bd-c81dd495e0ab',
}

export const NOT_EDIT_OPERATION_ORDER_STATE = [
  OPERATION_ORDER_STATE.APPROVED,
  OPERATION_ORDER_STATE.RECOVERED,
  OPERATION_ORDER_STATE.DELIVERED
];