import React, { Dispatch, SetStateAction, useCallback, useRef, useState } from 'react';
import { Grid, IconButton, Paper, Typography, useMediaQuery } from '@material-ui/core';
import PublishIcon from '@material-ui/icons/Publish';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import GetAppIcon from '@material-ui/icons/GetApp';
import { AnyObject } from 'src/commons';
import { FileTypeOperation } from 'src/app/models/business/Operation';
import { useTranslation } from '../../services/translation';
import { useStyles } from './style';

interface AttachmentFieldType {
  value: FileTypeOperation[];
  onChange: Dispatch<SetStateAction<FileTypeOperation[]>>;
  label?: string;
  height: string;
  isAsigned: boolean;
  disabled?: boolean;
}
let fileReader: AnyObject;

export const AttachmentField = ({
  value,
  onChange,
  label = 'Documentos',
  height = '200px',
  isAsigned = false,
  disabled,
}: AttachmentFieldType) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const fileRef = useRef<HTMLInputElement | null>(null);

  const handleFileRead = useCallback(
    async (event) => {
      const base64ToSave = event.target.result;
      onChange([...value, { result: base64ToSave, name: fileReader.fileName, base64: true }]);
    },
    [onChange, value],
  );

  const handleFileChosen = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const target = e.target as HTMLInputElement;
      const fileInner: File = (target.files as FileList)[0];
      fileReader = new FileReader();
      fileReader.fileName = fileInner && fileInner.name;
      fileReader.onload = handleFileRead;
      fileReader.readAsDataURL(fileInner);
    },
    [handleFileRead],
  );

  const handleDeleteFile = useCallback(
    (prop) => {
      const filterValue = value.filter((item, index) => index !== prop);
      onChange(filterValue);
    },
    [value, onChange],
  );

  return (
    <Grid style={{ height }}>
      <Grid className={classes.rootMessage} style={{ justifyContent: 'space-between' }}>
        <Grid className={classes.rootMessage}>
          {label && (
            <Typography align="left" variant="subtitle2" className={classes.labelInput}>
              {label}
            </Typography>
          )}
          <Grid className={classes.attachments}>
            {value[0] &&
              value.map((item, index) => (
                <Grid className={classes.innerAttachments}>
                  <Typography>{item.name}</Typography>
                  <Grid className={classes.editAttachments}>
                    <IconButton onClick={() => handleDeleteFile(index)} disabled={disabled}>
                      <DeleteOutlineIcon />
                    </IconButton>
                    {!item.base64 && (
                      <a download={item.name} href={item.result}>
                        <IconButton>
                          <GetAppIcon />
                        </IconButton>
                      </a>
                    )}
                  </Grid>
                </Grid>
              ))}
          </Grid>
        </Grid>
        <Grid className={classes.actions} style={{ display: isAsigned ? 'none' : '' }}>
          <label htmlFor="input" className={classes.label}>
            <IconButton component="span">
              <PublishIcon />
              <input
                disabled={disabled}
                ref={fileRef}
                id="input"
                type="file"
                accept="application/msword, application/vnd.ms-excel, .rtf, application/vnd.ms-powerpoint, text/plain, application/pdf, image/*"
                className={classes.inputField}
                onChange={(e) => {
                  handleFileChosen(e);
                  e.target.value = '';
                }}
              />
              <Typography className={classes.inputText}>{t('Adjuntar')}</Typography>
            </IconButton>
          </label>
        </Grid>
      </Grid>
    </Grid>
  );
};
