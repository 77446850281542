import { PAYMENT_STATE } from '../../../../commons';

export const paymentMethodPaymentPending: string[] = [
  PAYMENT_STATE.PAYMENT_PENDING,
  PAYMENT_STATE.REJECTED,
  PAYMENT_STATE.DOCUMENTATION_CONTROL,
];

export const paymentMethodDocumentationControl: string[] = [
  PAYMENT_STATE.DOCUMENTATION_CONTROL,
  PAYMENT_STATE.PENDING_DOCUMENTATION,
  PAYMENT_STATE.CONTROLLED_DOCUMENTATION,
];

export const paymentMethodPendingDocumentation: string[] = [
  PAYMENT_STATE.PENDING_DOCUMENTATION,
  PAYMENT_STATE.REJECTED,
  PAYMENT_STATE.PENDING_SIGNATURE,
];

export const paymentMethodControlledDocumentation: string[] = [
  PAYMENT_STATE.CONTROLLED_DOCUMENTATION,
  PAYMENT_STATE.REJECTED,
  PAYMENT_STATE.PENDING_SIGNATURE,
];

export const paymentMethodPendingSignature: string[] = [
  PAYMENT_STATE.PENDING_SIGNATURE,
  PAYMENT_STATE.APPROVED,
  PAYMENT_STATE.REJECTED,
];
