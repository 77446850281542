import React, { useMemo } from 'react';
import { Link } from '@material-ui/core';
import { formatPrice } from '../../../../utils';
import { OrderItem } from '../../../../app/models/business/OrderItem';

interface UseItemDetailProps {
  getRowHref: (row: OrderItem) => string;
}

export const useItemDetail = ({ getRowHref }: UseItemDetailProps) => {
  const columns = useMemo(
    () => [
      {
        id: 'product',
        label: 'Product',
        width: '20%',
        render: (value: string, row: OrderItem) => {
          const rowHref = getRowHref(row);
          return (
            <Link href={rowHref} target='_blank'>
              {row.product}
            </Link>
          );
        },
      },
      {
        id: 'quantity',
        label: 'Cantidad',
        width: '2%',
        render: (value: number, row: OrderItem) => row.quantity,
      },
      {
        id: 'unit_price',
        label: 'Precio Unitario',
        width: '5%',
        render: (value: number, row: OrderItem) => formatPrice(row.unit_price),
      },
      {
        id: 'total',
        label: 'Total',
        width: '5%',
        render: (value: number, row: OrderItem) => formatPrice(row.total),
      },
      {
        id: 'subtotal',
        label: 'Subtotal',
        width: '5%',
        render: (value: number, row: OrderItem) => formatPrice(row.subtotal),
      },
    ],
    [getRowHref],
  );

  return { columns };
};
